import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AssignmentProgress,
  EvaluationScoreItem,
  EvaluationScoreItemCatalog,
  EvaluationTarget,
  Rating,
} from '../../types/types';
import Accordion from '../core/layout/Accordion/Accordion';
import Button from '../core/button/Button/Button';
import ProgressRing from '../core/display/Progress/ProgressRing';
import HelpTag from '../core/display/HelpTag';
import EvalScoresTable from './EvalScoresTable';
import { getEvalRubricRatings } from '../../utils/requests';
import { useSelector } from 'react-redux';
import { selectAssignment, selectUser } from '../../store/selectors';
import AlertBar from '../core/display/AlertBar';

interface Props {
  catalog: EvaluationScoreItemCatalog;
  userProgress: AssignmentProgress;
}

function EvalSelectScreen({ catalog, userProgress }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const assignment = useSelector(selectAssignment);

  const percentComplete =
    userProgress.evaluationCount > 0 ? 100 * (userProgress.countEvaluationsComplete / userProgress.evaluationCount) : 0;
  return (
    <div className="main-menu">
      <div className="top-info">
        <span className="main-progress">
          <ProgressCheckIcon progress={percentComplete} /> {Math.round(percentComplete)}% Complete
        </span>
        <Button
          id="return-btn"
          variant="sm low"
          href={`/course/${courseId}/assignment/${assignmentId}/dashboard`}
          route
        >
          Return to Dashboard
        </Button>
      </div>

      <h1>Evaluations</h1>

      {assignment?.studentSelectedEvaluations ? (
        <>
          {userProgress.evaluationCount === 0 ? (
            <AlertBar backgroundColor="#ffefd2">You must choose which peers to evaluate in order to continue:</AlertBar>
          ) : null}
          <Button
            id="peer-select-btn"
            variant="low sm rad"
            href={`/course/${courseId}/assignment/${assignmentId}/evaluate/select_peers`}
            route
          >
            Choose Peers to Evaluate
          </Button>
        </>
      ) : null}

      {catalog.groupEvalItem !== null ? (
        <EvalCategoryAccordion
          name="Overall Group"
          type="GROUP"
          items={[catalog.groupEvalItem]}
          userProgress={userProgress}
        />
      ) : null}
      {catalog.groupMemberEvalItems.length > 0 ? (
        <EvalCategoryAccordion
          name="Group Members"
          type="MEMBER"
          items={catalog.groupMemberEvalItems}
          userProgress={userProgress}
        />
      ) : null}
      {catalog.groupLeaderEvalItem !== null ? (
        <EvalCategoryAccordion
          name="Group Leader"
          type="GROUP_LEADER"
          items={[catalog.groupLeaderEvalItem]}
          userProgress={userProgress}
        />
      ) : null}
      {catalog.instructorEvalItems.length > 0 ? (
        <EvalCategoryAccordion
          name="Instructors"
          type="INSTRUCTOR"
          items={catalog.instructorEvalItems}
          userProgress={userProgress}
        />
      ) : null}
    </div>
  );
}

interface EvalCategoryAccordionProps {
  name: string;
  items: EvaluationScoreItem[];
  type: EvaluationTarget;
  userProgress: AssignmentProgress;
}

function EvalCategoryAccordion({ name, items, type, userProgress }: EvalCategoryAccordionProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [rubricRatings, setRubricRatings] = useState<Rating[]>([]);

  useEffect(() => getEvalRubricRatings(assignmentId, type, setRubricRatings), [type, assignmentId]);

  const completeItems = useMemo(
    () =>
      items
        .filter((item) => item.peerEvaluation.complete)
        .sort((a, b) => {
          if (a.score < b.score) return 1;
          else if (a.score > b.score) return -1;
          return (a.targetUser?.name || '').localeCompare(b.targetUser?.name || '');
        }),
    [items],
  );
  const incompleteItems = useMemo(
    () =>
      items
        .filter((item) => !item.peerEvaluation.complete)
        .sort((a, b) => (a.targetUser?.name || '').localeCompare(b.targetUser?.name || '')),
    [items],
  );

  return (
    <Accordion
      name={name}
      headingContent={
        <div className="progress">
          <ProgressCheckIcon progress={100 * (completeItems.length / items.length)} />
          {completeItems.length}/{items.length} Complete
        </div>
      }
      className="eval-category-accordion"
      iconCodeCollapsed="navigate_next"
      iconCodeUncollapsed="expand_more"
      collapsedValue={incompleteItems.length < 1}
    >
      {incompleteItems.map((item) => (
        <ItemDetails
          key={item.targetUser?.userId ?? 'group'}
          item={item}
          userProgress={userProgress}
          rubricRatings={rubricRatings}
        />
      ))}
      {incompleteItems.length > 0 && completeItems.length > 0 ? <hr /> : null}
      {completeItems.map((item, i) => (
        <ItemDetails
          key={item.targetUser?.userId ?? 'group'}
          item={item}
          userProgress={userProgress}
          rubricRatings={rubricRatings}
          rank={i + 1}
        />
      ))}
    </Accordion>
  );
}

function ItemDetails({
  item,
  rank,
  rubricRatings,
  userProgress,
}: {
  item: EvaluationScoreItem;
  rank?: number;
  rubricRatings: Rating[];
  userProgress: AssignmentProgress;
}): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const { canEvaluate } = userProgress.status;

  const user = useSelector(selectUser);
  const assignment = useSelector(selectAssignment);

  const evalRatingIdToScoreMap = useMemo(() => {
    const map: { [index: string]: number } = {};
    item.peerEvaluation.reviewRatings.forEach((reviewRating) => (map[reviewRating.ratingId] = reviewRating.score));
    return map;
  }, [item]);

  const { score, targetUser, peerEvaluation } = item;
  const { complete, reviewComments, reviewRatings } = peerEvaluation;
  const started = !complete && (reviewComments.length > 0 || reviewRatings.length > 0);
  return (
    <div className="score-entry">
      <div className="main-partition">
        <span className="details-wrapper">
          {rank ? (
            <div className="rank" id={`rank-${rank}`}>
              {String(rank).padStart(2, '0')}
            </div>
          ) : (
            <div className="rank unranked">00</div>
          )}

          <span className="name">{targetUser?.name ?? 'Overall Group'}</span>
          {user.userId === targetUser?.userId ? <span className="badge">Self Evaluation</span> : null}
        </span>
        <span className="status-wrapper">
          {score > 0 ? (
            <span className="score">
              {score} <span className="points">pts</span>
            </span>
          ) : (
            <span className="scoreless">—</span>
          )}
          <HelpTag iconCode="info_outline" theme="light">
            <EvalScoresTable rubricRatings={rubricRatings} ratingIdToScoreMap={evalRatingIdToScoreMap} />
          </HelpTag>
        </span>
      </div>
      <Button
        variant={`rad sm low ${complete ? 'alt' : null}`}
        href={
          canEvaluate
            ? assignment?.pointsAllocationEvalEnabled && item.peerEvaluation.target === 'MEMBER'
              ? `/course/${courseId}/assignment/${assignmentId}/evaluate/points`
              : `/course/${courseId}/assignment/${assignmentId}/evaluate/${item.peerEvaluation?.peerEvalId}`
            : undefined
        }
        route
        disabled={!canEvaluate}
      >
        {complete ? 'Edit' : started ? 'Continue' : 'Start'}
      </Button>
      <ProgressCheckIcon progress={complete ? 100 : 0} />
    </div>
  );
}

function ProgressCheckIcon({ progress }: { progress: number }): JSX.Element {
  return (
    <ProgressRing
      size="sm"
      stroke="#38B934"
      progress={progress}
      radius={12}
      strokeWidth={3}
      padding={0}
      backgroundCircleOutline
      checkmarkIfComplete
    />
  );
}

export default EvalSelectScreen;
