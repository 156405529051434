import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AssignmentRubric, EvaluationTarget, Rubric } from '../../types/types';
import { updateEvalAssignmentRubric } from '../../utils/requests';
import RubricEditorPage from './RubricEditorPage';

interface Props {
  peerEvalGroupRubric: AssignmentRubric | null | undefined;
  peerEvalMemberRubric: AssignmentRubric | null | undefined;
  peerEvalLeaderRubric: AssignmentRubric | null | undefined;
  peerEvalInstructorRubric: AssignmentRubric | null | undefined;
  setPeerEvalGroupRubric: React.Dispatch<React.SetStateAction<AssignmentRubric | null | undefined>>;
  setPeerEvalMemberRubric: React.Dispatch<React.SetStateAction<AssignmentRubric | null | undefined>>;
  setPeerEvalLeaderRubric: React.Dispatch<React.SetStateAction<AssignmentRubric | null | undefined>>;
  setPeerEvalInstructorRubric: React.Dispatch<React.SetStateAction<AssignmentRubric | null | undefined>>;
}

function AssignmentEvalRubricPage({
  peerEvalGroupRubric,
  peerEvalMemberRubric,
  peerEvalLeaderRubric,
  peerEvalInstructorRubric,
  setPeerEvalGroupRubric,
  setPeerEvalMemberRubric,
  setPeerEvalLeaderRubric,
  setPeerEvalInstructorRubric,
}: Props): JSX.Element {
  const { assignmentId, target } = useParams() as { assignmentId: string; target: EvaluationTarget };

  const rubric = useMemo(() => {
    switch (target) {
      case 'GROUP':
        return peerEvalGroupRubric;
      case 'MEMBER':
        return peerEvalMemberRubric;
      case 'GROUP_LEADER':
        return peerEvalLeaderRubric;
      case 'INSTRUCTOR':
        return peerEvalInstructorRubric;
    }
  }, [target, peerEvalGroupRubric, peerEvalMemberRubric, peerEvalLeaderRubric, peerEvalInstructorRubric]);

  const setRubric = useMemo(() => {
    switch (target) {
      case 'GROUP':
        return setPeerEvalGroupRubric;
      case 'MEMBER':
        return setPeerEvalMemberRubric;
      case 'GROUP_LEADER':
        return setPeerEvalLeaderRubric;
      case 'INSTRUCTOR':
        return setPeerEvalInstructorRubric;
    }
  }, [target, setPeerEvalGroupRubric, setPeerEvalMemberRubric, setPeerEvalLeaderRubric, setPeerEvalInstructorRubric]);

  const onReorder = useCallback(
    (prompts: Rubric) => {
      if (rubric) {
        const newRubric: AssignmentRubric = {
          ...rubric,
          items: prompts,
        };
        updateEvalAssignmentRubric(assignmentId, target, newRubric, setRubric);
      }
    },
    [rubric, assignmentId, target, setRubric],
  );

  const onRename = useCallback(
    (name: string) => {
      if (rubric) updateEvalAssignmentRubric(assignmentId, target, { ...rubric, name }, setRubric);
    },
    [rubric, assignmentId, target, setRubric],
  );

  if (rubric) {
    return <RubricEditorPage rubric={rubric} onReorder={onReorder} onRename={onRename} visible={true} />;
  }

  return <></>;
}

export default AssignmentEvalRubricPage;
