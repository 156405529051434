import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCourse } from '../../../store/selectors';
import { Course } from '../../../types/types';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface Grading5Props {
  manualGradeRelease: boolean;
  hideRatingResults: boolean;
  hideCommentResults: boolean;
  hideGradeResults: boolean;
}

function GradingPage5({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<Grading5Props>): JSX.Element {
  const course = useSelector(selectCourse) as Course;
  const [manualGradeRelease, setManualGradeRelease] = useState(assignmentSettings.manualGradeRelease);
  const [hideRatingResults, setHideRatingResults] = useState(assignmentSettings.hideRatingResults);
  const [hideCommentResults, setHideCommentResults] = useState(assignmentSettings.hideCommentResults);
  const [hideGradeResults, setHideGradeResults] = useState(assignmentSettings.hideGradeResults);

  useEffect(() => {
    updateSettings({
      manualGradeRelease,
      hideRatingResults,
      hideCommentResults,
      hideGradeResults,
    });
  }, [updateSettings, manualGradeRelease, hideRatingResults, hideCommentResults, hideGradeResults]);

  return (
    <>
      <h3>Instructor Involvement</h3>
      <div className="sub-section">
        {!course.asyncEnabled ? (
          <fieldset>
            <legend>Grade Release</legend>
            <div className="rad-radio-btn">
              <input
                id="automaticRelease"
                type="radio"
                name="manualGradeRelease"
                value="false"
                checked={!manualGradeRelease}
                onChange={(e) => setManualGradeRelease(e.target.value === 'true')}
              />
              <label htmlFor="automaticRelease">Automatic</label>
            </div>
            <div className="rad-radio-btn">
              <input
                id="manualRelease"
                type="radio"
                name="manualGradeRelease"
                value="true"
                checked={manualGradeRelease}
                onChange={(e) => setManualGradeRelease(e.target.value === 'true')}
              />
              <label htmlFor="manualRelease">Manual</label>
            </div>
          </fieldset>
        ) : null}
        {manualGradeRelease && !assignmentSettings.groupFormationEnabled ? (
          <>
            <fieldset>
              <legend>Hide Rating Results</legend>
              <div className="rad-radio-btn">
                <input
                  id="hideRatingResults"
                  type="radio"
                  name="hideRatingResults"
                  value="false"
                  checked={hideRatingResults}
                  onChange={(e) => setHideRatingResults(e.target.value === 'false')}
                />
                <label htmlFor="hideRatingResults">True</label>
              </div>
              <div className="rad-radio-btn">
                <input
                  id="notHideRatingResults"
                  type="radio"
                  name="notHideRatingResults"
                  value="true"
                  checked={!hideRatingResults}
                  onChange={(e) => setHideRatingResults(e.target.value === 'false')}
                />
                <label htmlFor="notHideRatingResults">False</label>
              </div>
            </fieldset>
            <fieldset>
              <legend>Hide Comment Results</legend>
              <div className="rad-radio-btn">
                <input
                  id="hideCommentResults"
                  type="radio"
                  name="hideCommentResults"
                  value="false"
                  checked={hideCommentResults}
                  onChange={(e) => setHideCommentResults(e.target.value === 'false')}
                />
                <label htmlFor="hideCommentResults">True</label>
              </div>
              <div className="rad-radio-btn">
                <input
                  id="notHideCommentResults"
                  type="radio"
                  name="notHideCommentResults"
                  value="true"
                  checked={!hideCommentResults}
                  onChange={(e) => setHideCommentResults(e.target.value === 'false')}
                />
                <label htmlFor="notHideCommentResults">False</label>
              </div>
            </fieldset>
            <fieldset>
              <legend>Hide Grade Results</legend>
              <div className="rad-radio-btn">
                <input
                  id="hideGradeResults"
                  type="radio"
                  name="hideGradeResults"
                  value="false"
                  checked={hideGradeResults}
                  onChange={(e) => setHideGradeResults(e.target.value === 'false')}
                />
                <label htmlFor="hideGradeResults">True</label>
              </div>
              <div className="rad-radio-btn">
                <input
                  id="notHideGradeResults"
                  type="radio"
                  name="notHideGradeResults"
                  value="true"
                  checked={!hideGradeResults}
                  onChange={(e) => setHideGradeResults(e.target.value === 'false')}
                />
                <label htmlFor="notHideGradeResults">False</label>
              </div>
            </fieldset>
          </>
        ) : null}
      </div>
    </>
  );
}

export default GradingPage5;
