import React, { useCallback } from 'react';
import { Rating } from '../../types/types';
import Prompt from './Prompt';

interface Props {
  added?: boolean;
  customControls?: React.ReactNode;
  rating: Rating;
  onAdd?: (rating: Rating) => void;
  onEdit?: (rating: Rating) => void;
  onSelect?: (rating: Rating) => void;
}

function RatingPrompt({ added = false, customControls, onAdd, onEdit, onSelect, rating }: Props): JSX.Element {
  const { ratingResource } = rating;

  const handleEdit = useCallback(() => (onEdit ? onEdit(rating) : undefined), [onEdit, rating]);
  const handleSelect = useCallback(() => (onSelect ? onSelect(rating) : undefined), [onSelect, rating]);
  const handleAdd = useCallback(() => (onAdd ? onAdd(rating) : undefined), [onAdd, rating]);

  return (
    <Prompt
      iconCode="format_list_numbered"
      name={rating.name}
      label="Rating Prompt"
      status={
        rating.percentageScore ? `Score out of ${rating.maxScore}` : `1-${rating.ratingLevels.length} Rating Scale`
      }
      description={rating.prompt}
      tags={rating.tags}
      hiddenElements={
        <>
          {ratingResource !== null ? (
            <table className="prompt-table">
              <thead className="sr-only">
                <tr>
                  <th>Score Threshold</th>
                  <th>Resource</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>&lt;{ratingResource.scoreThreshold}</th>
                  <td>
                    <a href={ratingResource.url ?? undefined} target="_blank" rel="noreferrer">
                      {ratingResource.resourceName}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
          <p>
            <b>Weight:</b> {rating.ratingWeight.toFixed(1)}
          </p>
        </>
      }
      onAdd={onAdd ? handleAdd : undefined}
      onEdit={onEdit ? handleEdit : undefined}
      onSelect={onSelect ? handleSelect : undefined}
      customControls={customControls}
      color="#d7833b"
      added={added}
    >
      {!rating.percentageScore ? (
        <table className="prompt-table">
          <thead className="sr-only">
            <tr>
              <th>Score</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {rating.ratingLevels
              .map((level) => (
                <tr key={level.score}>
                  <th>{level.score}</th>
                  <td>{level.levelDescription}</td>
                </tr>
              ))
              .reverse()}
          </tbody>
        </table>
      ) : null}
    </Prompt>
  );
}

export default RatingPrompt;
