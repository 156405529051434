import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grade, AsyncResult, Result, Group } from '../../types/types';
import Histogram from '../core/display/Graph/Histogram';
import Table, { getCellWithUnit } from '../core/display/Table/Table';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { getGroupMembershipMap, getInstructorResultsAll } from '../../utils/requests';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../core/button/Button/Button';
import Icon from '../core/display/Icon';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import { TeacherResultsTabProps } from './TeacherResultsPage';
import { useSelector } from 'react-redux';
import { selectAssignment } from '../../store/selectors';
import { ColumnDef } from '@tanstack/react-table';
import HelpTag from '../core/display/HelpTag';
import { Tooltip, TooltipContent, TooltipTrigger } from '../core/layout/Tooltip/Tooltip';
import { performanceTextFromPAF } from './StudentEvalResults';

type SyncTableData = {
  late?: boolean;
  overall?: number;
  override?: boolean;
  peerEval?: number;
  evalRating?: number;
  evalCompletion?: number;
  review?: number;
  submission?: number;
  task?: number;
  submissionLatePenalty?: number;
  peerAssessmentFactor?: number;
};

type AsyncTableData = {
  taskStatus?: 'Complete' | 'Incomplete';
  reviewStatus?: 'Pass' | 'Fail';
  genGrade?: number;
};

type TableData = {
  assignmentId: string;
  name: string;
  userId: string;
  groupName?: string;
} & SyncTableData &
  AsyncTableData;

function TeacherGradesBreakdown({ updateKey, accessPermission }: TeacherResultsTabProps): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const assignment = useSelector(selectAssignment);

  const [data, setData] = useState<Result[] | null>(null);
  const [graphData, setGraphData] = useState<number[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData>[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [graphDimensions, setGraphDimensions] = useState<{ width: number; height: number } | null>(null);
  const [filterList, setFilterList] = useState<string[]>([]);
  const [groupMembershipMap, setGroupMembershipMap] = useState<{ [index: string]: Group }>({});

  const filteredTableData = useMemo(
    () =>
      tableData.filter((d) => {
        if (filterList.includes('Late Submissions')) return d.late === true;
        return d;
      }),
    [tableData, filterList],
  );

  const lateIcon = (penalty: number) => (
    <Icon
      key="late-icon"
      className="header-icon"
      code="watch_later"
      label={`Late Submission (${penalty > 0 ? `Penalty: -${penalty}%` : 'No Penalty'})`}
      tooltip
    />
  );
  const overrideIcon = useMemo(
    () => <Icon key="override-icon" className="header-icon" code="gavel" label="Overridden" tooltip />,
    [],
  );

  const navigate = useNavigate();

  useEffect(() => getInstructorResultsAll(assignmentId, setData), [updateKey, assignmentId]);

  useEffect(() => getGroupMembershipMap(assignmentId, setGroupMembershipMap), [assignmentId]);

  const handleRowSelect = useCallback(
    (userId: string, assignmentId: string) =>
      navigate(`/course/${courseId}/assignment/${assignmentId}/student/${userId}`),
    [courseId, navigate],
  );

  useEffect(() => {
    const parseDataForGraph = (data: Result[]) => {
      const cumulativeData: number[] = [];
      data.forEach((gradeEntry: { grade: Grade; asyncResult: AsyncResult }) => {
        if (gradeEntry.grade) cumulativeData.push(gradeEntry.grade.overallGrade);
        else if (gradeEntry.asyncResult) cumulativeData.push(gradeEntry.asyncResult.generatedGrade);
      });
      setGraphData(cumulativeData);
    };

    const parseDataForTable = (data: Result[]) => {
      const columns: ColumnDef<TableData>[] = [
        { header: 'Name', accessorKey: 'name', meta: { className: 'left-align' } },
      ];
      if (assignment?.groupsEnabled)
        columns.push({ header: 'Group', accessorKey: 'groupName', meta: { className: 'left-align' } });
      const dataTable: TableData[] = [];
      data.forEach((result: Result, i: number) => {
        const name = result.user.sortableName;
        const userId = result.userId;
        const assignmentId = result.assignmentId;
        const group = groupMembershipMap[userId];

        const { grade, asyncResult } = result;

        const newRow: TableData = { name, userId, assignmentId };

        if (grade && assignment) {
          newRow[`overall`] = grade.overallGrade;
          newRow[`submission`] = grade.submissionGrade;
          newRow[`review`] = grade.reviewingGrade;
          newRow[`task`] = grade.taskGrade;
          newRow[`peerEval`] = grade.peerEvaluationGrade;
          newRow[`evalCompletion`] = grade.evalCompletionGrade;
          newRow[`evalRating`] = grade.evalRatingGrade;
          newRow[`late`] = result.lateSubmission;
          newRow[`override`] = result.override;
          newRow[`submissionLatePenalty`] = grade.submissionLatePenalty;
          newRow[`groupName`] = group ? group.groupName : undefined;
          newRow[`peerAssessmentFactor`] = result.peerAssessmentFactor;
          if (i === 0) {
            columns.push({
              header: 'Overall',
              accessorKey: `overall`,
              cell: (cell) => {
                const value = cell.getValue() as number;
                const icons = [];
                if (cell.row.original.override) icons.push(overrideIcon);
                return getCellWithUnit(value.toFixed(2), ' %', icons);
              },
            });
            if (!assignment.instructorUpload && !assignment.peerEvaluationOnly && !assignment.groupFormationOnly)
              columns.push({
                header: 'Submission',
                accessorKey: `submission`,
                cell: (cell) => {
                  const value = cell.getValue() as number;
                  if (value < 0) return <>Pending</>;
                  if (cell.row.original.late) {
                    return getCellWithUnit(value.toFixed(2), ' %', [
                      lateIcon(cell.row.original.submissionLatePenalty as number),
                    ]);
                  }
                  return getCellWithUnit(value.toFixed(2), ' %');
                },
              });
            if (!assignment.instructorGradedOnly && !assignment.peerEvaluationOnly && !assignment.groupFormationOnly) {
              columns.push({
                header: 'Review',
                accessorKey: `review`,
                cell: (cell) => getCellWithUnit((cell.getValue() as number).toFixed(2), ' %'),
              });
              columns.push({
                header: 'Task',
                accessorKey: `task`,
                cell: (cell) => getCellWithUnit((cell.getValue() as number).toFixed(2), ' %'),
              });
            }
            if (assignment.peerEvaluationEnabled && !assignment.peerEvaluationOnly) {
              columns.push({
                header: 'Team Member Evaluation',
                accessorKey: `peerEval`,
                cell: (cell) => getCellWithUnit((cell.getValue() as number).toFixed(2), ' %'),
              });
            }
            if (assignment.peerEvaluationEnabled) {
              columns.push({
                header: 'Eval. Rating',
                accessorKey: `evalRating`,
                cell: (cell) => getCellWithUnit((cell.getValue() as number).toFixed(2), ' %'),
              });
              columns.push({
                header: 'Eval. Completion',
                accessorKey: `evalCompletion`,
                cell: (cell) => getCellWithUnit((cell.getValue() as number).toFixed(2), ' %'),
              });
            }
            if (assignment.pointsAllocationEvalEnabled) {
              columns.push({
                header: () => (
                  <span>
                    PEF{' '}
                    <HelpTag margin="0 0 0 0.25rem">
                      <p>
                        <b>Peer Evaluation Factor (PEF)</b> measures the share of points each student received relative
                        to other team members.
                      </p>
                      <p>
                        A student with a PEF of 1.0 has received their exact share of points, meaning they perfectly met
                        expecations. Students above 1.0 overperformed, and students below 1.0 underperformed.
                      </p>
                      <p>PEF is calculated as follows:</p>
                      <pre style={{ backgroundColor: 'black', textWrap: 'balance' }}>
                        <code>
                          PEF = ((Sum of Scores Received) * (Number of Students In Team)) / (100 * (Number of Students
                          Completing Evaluation))
                        </code>
                      </pre>
                    </HelpTag>
                  </span>
                ),
                accessorKey: `peerAssessmentFactor`,
                cell: (cell) => {
                  const paf = cell.getValue() as number;
                  return (
                    <Tooltip>
                      <TooltipTrigger
                        tag="div"
                        className={`pafPerformanceIndicator ${performanceTextFromPAF(paf, 'under', 'default', 'over')}`}
                        tabIndex={0}
                      >
                        {paf}
                      </TooltipTrigger>
                      <TooltipContent>
                        <b>
                          {performanceTextFromPAF(paf, 'Underperformed', 'Performed satisfactorily', 'Overperformed')}
                        </b>{' '}
                        relative to peers
                      </TooltipContent>
                    </Tooltip>
                  );
                },
              });
            }
          }
        } else if (asyncResult) {
          newRow[`taskStatus`] = asyncResult.taskCheck ? 'Complete' : 'Incomplete';
          newRow[`reviewStatus`] = asyncResult.reviewCheck ? 'Pass' : 'Fail';
          newRow[`genGrade`] = asyncResult.generatedGrade;
          if (i === 0) {
            columns.push({ header: 'Task Status', accessorKey: `taskStatus` });
            columns.push({ header: 'Review Status', accessorKey: `reviewStatus` });
            columns.push({
              header: 'Grade',
              accessorKey: `genGrade`,
              cell: (cell) => `${(cell.getValue() as number).toFixed(2)} %`,
            });
          }
        }

        dataTable.push(newRow);
      });

      setTableData(dataTable);
      setTableColumns(columns);
    };

    if (data) {
      parseDataForGraph(data);
      parseDataForTable(data);
      setLoaded(true);
    }
  }, [assignment, data, groupMembershipMap, overrideIcon]);

  const updateGraphDimensions = () => {
    const elem = document.getElementById('breakdown-graph-wrapper');
    if (elem) {
      elem.classList.add('no-children');
      setGraphDimensions({ width: elem.offsetWidth, height: elem.offsetHeight });
      elem.classList.remove('no-children');
    }
  };

  useEffect(() => {
    if (loaded) {
      window.addEventListener('resize', updateGraphDimensions);
      updateGraphDimensions();
    }

    return () => window.removeEventListener('resize', updateGraphDimensions);
  }, [loaded]);

  if (loaded) {
    return (
      <>
        {graphData.length > 0 ? (
          <section>
            <div className="panel-sm panel-white" id="breakdown-graph-card">
              <h2 className="title">Overall Grade Distribution</h2>
              <div className="graph-wrapper" id="breakdown-graph-wrapper">
                {graphData.length > 0 ? (
                  graphDimensions === null || (graphDimensions.width === 0 && graphDimensions.height) === 0 ? (
                    <Button variant="rad low sm" onClick={updateGraphDimensions}>
                      Show Graph
                    </Button>
                  ) : (
                    <Histogram
                      width={graphDimensions.width}
                      height={graphDimensions.height}
                      data={graphData}
                      bins={20}
                    />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
        ) : (
          <div className="panel unavailable-card">
            <h2>Overall Grade Distribution Unavailable</h2>
          </div>
        )}

        {tableData.length > 0 ? (
          <section>
            <Table
              columns={tableColumns}
              data={filteredTableData}
              sortBy="name"
              title="Individual Assignment Grades"
              id="individual-assignment-grades-card"
              informOfRow={(row) => handleRowSelect(row.original.userId, row.original.assignmentId)}
              hideDownload={!accessPermission.downloadResultPermission}
            >
              {assignment && !assignment.groupFormationOnly ? (
                <FilterTab label="Show:" setFilterList={setFilterList}>
                  <FilterTab.Button id="btn-all" type="radio" name="grades-filters" defaultChecked={true}>
                    All
                  </FilterTab.Button>
                  <FilterTab.Button id="btn-late" type="radio" name="grades-filters">
                    Late Submissions
                  </FilterTab.Button>
                </FilterTab>
              ) : null}
            </Table>
          </section>
        ) : (
          <div className="panel unavailable-card">
            <h2>Individual Assignment Grades Unavailable</h2>
          </div>
        )}
      </>
    );
  }
  return <LoadingSpinner />;
}

export default TeacherGradesBreakdown;
