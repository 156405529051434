import React, { useState, useEffect } from 'react';
import HelpTag from '../../core/display/HelpTag';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface EvaluationProps {
  memberEvalEnabled: boolean;
  groupEvalEnabled: boolean;
  instructorEvalEnabled: boolean;
  leaderEvalEnabled: boolean;
  selfEvalEnabled: boolean;
  showEvaluatorIdentities: boolean;
  pointsAllocationEvalEnabled: boolean;
}

function EvaluationPage({
  assignmentSettings,
  updateSettings,
}: AssignmentWizardPageProps<EvaluationProps>): JSX.Element {
  const [memberEvalEnabled, setMemberEvalEnabled] = useState(assignmentSettings.memberEvalEnabled);
  const [groupEvalEnabled, setGroupEvalEnabled] = useState(assignmentSettings.groupEvalEnabled);
  const [instructorEvalEnabled, setInstructorEvalEnabled] = useState(assignmentSettings.instructorEvalEnabled);
  const [leaderEvalEnabled, setLeaderEvalEnabled] = useState(assignmentSettings.leaderEvalEnabled);
  const [selfEvalEnabled, setSelfEvalEnabled] = useState(assignmentSettings.selfEvalEnabled);
  const [showEvaluatorIdentities, setShowEvaluatorIdentities] = useState(assignmentSettings.showEvaluatorIdentities);
  const [pointsAllocationEvalEnabled, setPointsAllocationEvalEnabled] = useState(
    assignmentSettings.pointsAllocationEvalEnabled,
  );

  useEffect(() => {
    updateSettings({
      memberEvalEnabled,
      groupEvalEnabled,
      instructorEvalEnabled,
      leaderEvalEnabled,
      selfEvalEnabled,
      showEvaluatorIdentities,
      pointsAllocationEvalEnabled,
    });
  }, [
    updateSettings,
    groupEvalEnabled,
    instructorEvalEnabled,
    leaderEvalEnabled,
    selfEvalEnabled,
    showEvaluatorIdentities,
    memberEvalEnabled,
    pointsAllocationEvalEnabled,
  ]);

  useEffect(() => {
    if (!memberEvalEnabled && !groupEvalEnabled && !leaderEvalEnabled && !instructorEvalEnabled) {
      setMemberEvalEnabled(true);
    }
    if (
      assignmentSettings.peerEvaluationOnly &&
      assignmentSettings.reflectionEnabled &&
      !groupEvalEnabled &&
      !leaderEvalEnabled &&
      !instructorEvalEnabled
    ) {
      setPointsAllocationEvalEnabled(false);
    }
  }, [assignmentSettings, groupEvalEnabled, memberEvalEnabled, instructorEvalEnabled, leaderEvalEnabled]);

  useEffect(() => {
    if (!memberEvalEnabled) setPointsAllocationEvalEnabled(false);
  }, [memberEvalEnabled]);

  return (
    <>
      <div className="sub-section">
        <p>
          Team Member Evaluation asks students to rate and comment on the performance of their group members. The
          following options are in addition to a standard team member evaluation assignment.
        </p>
        <p>Choose which evaluation options to enable:</p>

        <div>
          <input
            id="memberEvalEnabled"
            name="memberEvalEnabled"
            type="checkbox"
            checked={memberEvalEnabled}
            onChange={(e) => setMemberEvalEnabled(e.target.checked)}
            disabled={!groupEvalEnabled && !leaderEvalEnabled && !instructorEvalEnabled}
          />
          <label htmlFor="memberEvalEnabled">Require students to evaluate their group&apos;s peers (Default)</label>
          <HelpTag>This can be toggled off, but only if another evaluation type below is selected.</HelpTag>
        </div>

        {!assignmentSettings.studentSelectedEvaluations ? (
          <div className="indent">
            <input
              id="pointsAllocationEvalEnabled"
              name="pointsAllocationEvalEnabled"
              type="checkbox"
              checked={pointsAllocationEvalEnabled}
              onChange={(e) => setPointsAllocationEvalEnabled(e.target.checked)}
              disabled={
                !memberEvalEnabled ||
                (assignmentSettings.peerEvaluationOnly &&
                  assignmentSettings.reflectionEnabled &&
                  memberEvalEnabled &&
                  !groupEvalEnabled &&
                  !leaderEvalEnabled &&
                  !instructorEvalEnabled)
              }
            />
            <label htmlFor="pointsAllocationEvalEnabled">Use a Points Allocation type evaluation model</label>
            <HelpTag>
              <b>Points Allocation</b> allows students to evaluate their peers by distributing a finite number of points
              (100) among their peers based on their performance.
            </HelpTag>
          </div>
        ) : null}

        <div>
          <input
            id="groupEvalEnabled"
            name="groupEvalEnabled"
            type="checkbox"
            checked={groupEvalEnabled}
            onChange={(e) => {
              setGroupEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="groupEvalEnabled">Require students to evaluate the group as a whole</label>
          <HelpTag>This asks group members to evaluate the effectiveness of their group.</HelpTag>
        </div>

        <div>
          <input
            id="leaderEvalEnabled"
            name="leaderEvalEnabled"
            type="checkbox"
            checked={leaderEvalEnabled}
            onChange={(e) => {
              setLeaderEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="leaderEvalEnabled">Require students to evaluate the group leader</label>
          <HelpTag>Group leaders must be indicated for this assignment.</HelpTag>
        </div>

        <div>
          <input
            id="instructorEvalEnabled"
            name="instructorEvalEnabled"
            type="checkbox"
            checked={instructorEvalEnabled}
            onChange={(e) => {
              setInstructorEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="instructorEvalEnabled">Require students to evaluate the instructors</label>
        </div>

        <div>
          <input
            id="selfEvalEnabled"
            name="selfEvalEnabled"
            type="checkbox"
            checked={selfEvalEnabled}
            onChange={(e) => {
              setSelfEvalEnabled(e.target.checked);
            }}
          />
          <label htmlFor="selfEvalEnabled">Require students perform a self-evaluation</label>
          <HelpTag>Student self-ratings are not counted toward the overall rating received.</HelpTag>
        </div>

        <div>
          <input
            id="showEvaluatorIdentities"
            name="showEvaluatorIdentities"
            type="checkbox"
            checked={!showEvaluatorIdentities}
            onChange={(e) => {
              setShowEvaluatorIdentities(!e.target.checked);
            }}
          />
          <label htmlFor="showEvaluatorIdentities">Evaluators are anonymous</label>
          <HelpTag>
            If enabled, the names of evaluators and their provided scores/comments will be hidden from students.
          </HelpTag>
        </div>
      </div>
    </>
  );
}

export default EvaluationPage;
