import React, { useMemo } from 'react';
import { Rating } from '../../types/types';

interface Props {
  rubricRatings: Rating[];
  ratingIdToScoreMap: { [index: string]: number };
}

function EvalScoresTable({ ratingIdToScoreMap, rubricRatings }: Props): JSX.Element {

  const score = useMemo(
    () => Object.values(ratingIdToScoreMap).reduce((partialSum, a) => partialSum + a, 0),
    [ratingIdToScoreMap],
  );

  const maxScore = useMemo(() => {
    let sum = 0;
    rubricRatings.forEach((rating) => (sum += rating.percentageScore ? 100 : rating.ratingLevels.length));
    return sum;
  }, [rubricRatings]);

  return (
    <table className={`eval-rating-scores ${rubricRatings.length % 2 === 0 ? 'even' : 'odd'}`}>
      <tbody>
        {rubricRatings.map((rating) => {
          const currScore = ratingIdToScoreMap.hasOwnProperty(rating.ratingId)
            ? ratingIdToScoreMap[rating.ratingId]
            : -1;
          const fractionDigits = Number.isInteger(currScore) ? 0 : 1;
          return (
            <tr key={`rating-${rating.ratingId}`}>
              <th>{rating.name}</th>
              <td>
                {currScore > -1 ? currScore.toFixed(fractionDigits) : '—'} /{' '}
                {rating.percentageScore ? '100' : rating.ratingLevels.length}
              </td>
            </tr>
          );
        })}
        <tr id="overall">
          <th>Overall</th>
          <td>
            {score > 0 ? (Number.isInteger(score) ? score : score.toFixed(1)) : '—'} / {maxScore}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default EvalScoresTable;
