import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Assignment, AssignmentRubric, EvaluationTarget, Rubric } from '../../types/types';
import { updateEvalAssignmentRubric } from '../../utils/requests';
import RubricEditorPage from './RubricEditorPage';

interface Props {
  reflectionRubric: AssignmentRubric | null | undefined;
  assignment: Assignment;
  setReflectionRubric: React.Dispatch<React.SetStateAction<AssignmentRubric | null | undefined>>;
  updateData: () => void;
}

function AssignmentReflectionRubricPage({ reflectionRubric, setReflectionRubric, updateData }: Props): JSX.Element {
  const { assignmentId, target } = useParams() as { assignmentId: string; target: EvaluationTarget };

  const onReorder = useCallback(
    (prompts: Rubric) => {
      if (reflectionRubric) {
        const newRubric: AssignmentRubric = {
          ...reflectionRubric,
          items: prompts,
        };
        updateEvalAssignmentRubric(assignmentId, target, newRubric, setReflectionRubric);
        updateData();
      }
    },
    [reflectionRubric, assignmentId, target, setReflectionRubric, updateData],
  );

  const onRename = useCallback(
    (name: string) => {
      if (reflectionRubric)
        updateEvalAssignmentRubric(assignmentId, target, { ...reflectionRubric, name }, setReflectionRubric);
      updateData();
    },
    [reflectionRubric, assignmentId, target, setReflectionRubric, updateData],
  );
  if (reflectionRubric) {
    return <RubricEditorPage rubric={reflectionRubric} onReorder={onReorder} onRename={onRename} />;
  }

  return <></>;
}

export default AssignmentReflectionRubricPage;
