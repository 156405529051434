import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Table, { getCellWithUnit } from '../core/display/Table/Table';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import Dropdown from '../core/button/Dropdown/Dropdown';
import _ from 'lodash';
import {
  AccessPermission,
  Assignment,
  AssignmentDetailedResults,
  AverageRating,
  EvaluationTarget,
} from '../../types/types';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { ColumnDef, Row } from '@tanstack/react-table';
import Toggle from '../core/input/Toggle/Toggle';

interface Props {
  results: AssignmentDetailedResults[];
  evalTarget?: EvaluationTarget;
  accessPermission?: AccessPermission;
  assignment?: Assignment | null;
}

type TableData = {
  assignmentId: string;
  conflict: boolean;
  name: string;
  userId: string;
  submissionId?: string;
} & Record<string, unknown>;

function TeacherIndividualScoresTable({ assignment, results, accessPermission, evalTarget }: Props): JSX.Element {
  const { courseId } = useParams() as { courseId: string };
  const [data, setData] = useState<TableData[]>([]);
  const [columns, setColumns] = useState<ColumnDef<TableData, string>[]>([]);
  const [colGroups, setColGroups] = useState<string[][]>([]);
  const [toggleHiddensByGroup, setToggleHiddensByGroup] = useState<((value: boolean) => void)[][]>([]);
  const [seeConflicts, setSeeConflicts] = useState(false);
  const [headerIconMap, setHeaderIconMap] = useState<{ [index: string]: string }>({});
  const [colsFilteredList, setColsFilteredList] = useState<string[]>([]);
  const [rowsFilterList, setRowsFilterList] = useState<string[]>([]);
  const [showSelfRatings, setShowSelfRatings] = useState(false);

  const navigate = useNavigate();

  const groupByGroups = useMemo(() => rowsFilterList.includes('Group'), [rowsFilterList]);

  const mapHeaderToIcon = useCallback((accessor: string, iconCode: string) => {
    setHeaderIconMap((prevState) => {
      const newState = _.clone(prevState);
      newState[accessor] = iconCode;
      return newState;
    });
  }, []);

  const handleRowSelect = useCallback(
    (rowData: TableData) => {
      if (assignment?.instructorUpload)
        navigate(`/course/${courseId}/assignment/${rowData.assignmentId}/submission/${rowData.submissionId}`);
      else navigate(`/course/${courseId}/assignment/${rowData.assignmentId}/student/${rowData.userId}`);
    },
    [assignment, courseId, navigate],
  );

  const informOfRow = useCallback((row: Row<TableData>) => handleRowSelect(row.original), [handleRowSelect]);

  useEffect(() => {
    if (results) {
      const columns: ColumnDef<TableData>[] = [
        { accessorKey: 'name', header: 'Name', meta: { className: 'left-align' } },
      ];
      if (assignment?.groupsEnabled)
        columns.splice(groupByGroups ? 0 : 1, 0, {
          accessorKey: 'groupName',
          header: 'Group',
          meta: { className: 'left-align' },
        });
      const data: TableData[] = [];
      const colGroups: string[][] = [[], []];
      results.forEach((result: AssignmentDetailedResults, i: number) => {
        const newRow: TableData = {
          name: result.user
            ? result.user.sortableName
            : result.assignmentGroup
            ? result.assignmentGroup.groupName
            : result.name
            ? result.name
            : 'N/A',
          userId: result.userId,
          conflict: result.conflictingRatings,
          assignmentId: result.assignmentId,
          submissionId: result.submission?.submissionId,
          groupName: result.assignmentGroup?.groupName,
        };

        result.averagesByRating.forEach((averageByRating, j: number) => {
          newRow[`rating${j}`] = averageByRating.averageRating;
          newRow[`rating${j}_obj`] = averageByRating;
          if (i === 0) {
            columns.push({
              accessorKey: `rating${j}`,
              header: averageByRating.rating?.name,
              cell: (cell) => {
                const value = cell.getValue() as number;
                if (value < 0) return <>N/A</>;
                const storedAvgByRating = cell.row.original[`rating${j}_obj`] as AverageRating | undefined;
                return (
                  <>
                    {getCellWithUnit(value.toFixed(1), ` / ${averageByRating.rating?.maxScore}`)}
                    {showSelfRatings && storedAvgByRating ? (
                      <div>
                        (Self: {getCellWithUnit(storedAvgByRating.selfRating, ` / ${averageByRating.rating?.maxScore}`)}
                        )
                      </div>
                    ) : null}
                  </>
                );
              },
            });
            colGroups[0].push(`rating${j}`);
            mapHeaderToIcon(`rating${j}`, 'format_list_numbered');
          }
        });

        result.averagesByTag.forEach((tag, j: number) => {
          newRow[`tag${j}`] = tag.averageRating;
          if (i === 0) {
            columns.push({
              accessorKey: `tag${j}`,
              header: tag.tag?.content,
              cell: (cell) => {
                const value = cell.getValue() as number;
                if (value < 0) return <>N/A</>;
                return getCellWithUnit(Math.round(value), ` %`);
              },
            });
            colGroups[1].push(`tag${j}`);
            mapHeaderToIcon(`tag${j}`, 'local_offer');
          }
        });

        data.push(newRow);
      });

      setData(data);
      setColumns(columns);
      setColGroups(colGroups);
    }
  }, [results, mapHeaderToIcon, assignment, groupByGroups, showSelfRatings]);

  useEffect(() => {
    if (toggleHiddensByGroup.length >= 2) {
      toggleHiddensByGroup[0].forEach((toggleHidden) => toggleHidden(colsFilteredList.includes('Rating Prompts')));
      toggleHiddensByGroup[1].forEach((toggleHidden) => toggleHidden(colsFilteredList.includes('Tags')));
    }
  }, [colsFilteredList, toggleHiddensByGroup]);

  if (assignment) {
    const { instructorUpload } = assignment;
    return (
      <Table
        columns={columns}
        data={data}
        sortBy={groupByGroups ? 'groupName' : 'name'}
        title={`${instructorUpload ? 'Submission' : 'Individual'} Scores`}
        colGroups={colGroups}
        setToggleHiddensByGroup={setToggleHiddensByGroup}
        getRowProps={
          seeConflicts
            ? (row) => ({
                style: {
                  background: row.original.conflict ? '#ffe3e3' : 'white',
                  fontWeight: row.original.conflict ? 'bold' : undefined,
                },
              })
            : undefined
        }
        headerIconMap={headerIconMap}
        informOfRow={informOfRow}
        hideDownload={!accessPermission?.downloadResultPermission}
        lockSortColumnId={groupByGroups ? 'groupName' : undefined}
        mergeLockedColCells={groupByGroups}
      >
        {evalTarget === 'MEMBER' && assignment.groupsEnabled ? (
          <FilterTab label="Group By:" setFilterList={setRowsFilterList}>
            <FilterTab.Button id="btn-group" type="checkbox" name="rows-group-by" iconCode="group">
              Group
            </FilterTab.Button>
          </FilterTab>
        ) : null}
        <FilterTab setFilterList={setColsFilteredList} label="Show:">
          <FilterTab.Button
            id="btn-ratings"
            type="radio"
            name="individual-scores-display"
            iconCode="format_list_numbered"
            defaultChecked={true}
          >
            Rating Prompts
          </FilterTab.Button>
          <FilterTab.Button
            id="btn-tags"
            type="radio"
            name="individual-scores-display"
            iconCode="local_offer"
            defaultChecked={false}
          >
            Tags
          </FilterTab.Button>
        </FilterTab>
        {(assignment.selfReviewEnabled && evalTarget === undefined) || (assignment.selfEvalEnabled && evalTarget) ? (
          <Toggle
            checked={showSelfRatings}
            onChange={(e) => setShowSelfRatings(e.target.checked)}
            style={{ justifyContent: 'center' }}
          >
            Show Self Ratings
          </Toggle>
        ) : null}
        <div className="card-menu-wrapper">
          <Dropdown>
            <Dropdown.Link
              onClick={() => {
                setSeeConflicts((prevState) => !prevState);
              }}
            >
              {seeConflicts ? 'Hide' : 'See'} Conflicting Ratings
            </Dropdown.Link>
          </Dropdown>
        </div>
      </Table>
    );
  }
  return <LoadingSpinner />;
}

export default TeacherIndividualScoresTable;
