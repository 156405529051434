import { Assignment } from '../types/types';

export const API_URL =
  process.env.REACT_APP_API_URL ??
  (process.env.REACT_APP_ENVIRONMENT === 'test'
    ? 'https://build.api.peerceptiv.com'
    : 'https://dev.api.peerceptiv.com');

export const PRODUCTION_ENV = process.env.NODE_ENV === 'production';
export const TEST_ENV = process.env.REACT_APP_ENVIRONMENT === 'test';

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const SENTRY_IGNORE_LIST = ['ResizeObserver loop limit exceeded'];

export const CLEAR_REVIEW_DATA = 'CLEAR_REVIEW_DATA';
export const SET_COMMENT_TABLE = 'SET_COMMENT_TABLE';
export const UPDATE_COMMENT_TABLE = 'UPDATE_COMMENT_TABLE';
export const SET_RATING_TABLE = 'SET_RATING_TABLE';
export const UPDATE_RATING_TABLE = 'UPDATE_RATING_TABLE';
export const UPDATE_PREVIOUS_TABLES = 'UPDATE_PREVIOUS_TABLES';
export const SET_SAVE_TIMESTAMP = 'SET_SAVE_TIMESTAMP';
export const SET_FEEDBACK_TABLE = 'SET_FEEDBACK_TABLE';
export const UPDATE_FEEDBACK_TABLE = 'UPDATE_FEEDBACK_TABLE';
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_COURSE_USER = 'CLEAR_COURSE_USER';
export const SET_ASSIGNMENT = 'SET_ASSIGNMENT';
export const CLEAR_ASSIGNMENT = 'CLEAR_ASSIGNMENT';
export const SET_COURSE = 'SET_COURSE';
export const CLEAR_COURSE = 'CLEAR_COURSE';
export const NEW_UPDATE_KEY = 'NEW_UPDATE_KEY';
export const LOG_ERROR = 'LOG_ERROR';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const ACTIVATE_PINNING = 'ACTIVATE_PINNING';
export const DEACTIVATE_PINNING = 'DEACTIVATE_PINNING';
export const SET_COMMENT_ORDER = 'SET_COMMENT_ORDER';
export const SEEK_COMMENT = 'SEEK_COMMENT';
export const UNSEEK_COMMENT = 'UNSEEK_COMMENT';
export const SET_PIN_TYPE = 'SET_PIN_TYPE';

export const SAVE_DEBOUNCE_WAIT = 1000; // in milliseconds
export const SAVE_DEBOUNCE_MAX_WAIT = 5000; // in milliseconds
export const SCREEN_WIDTH_LAPTOP = 1024;
export const NOTIFICATION_BAR_ID = 'notification-bar';
export const SWIPE_THRESHOLD_FACTOR = 1 / 4;
export const MINIMUM_SWIPE_FACTOR = 1 / 10;
export const PROMPTS_PER_PAGE = 10;

export const VARIANT_LARGE = 'lg';
export const VARIANT_SMALL = 'sm';
export const VARIANT_REVIEW = 'review';
export const VARIANT_FEEDBACK = 'feedback';

export const SCREEN_WIDTH_LAPTOP_LG = 1439;
export const SCREEN_WIDTH_LAPTOP_SM = 1023;
export const SCREEN_WIDTH_TABLET = 767;
export const SCREEN_WIDTH_MOBILE_LG = 424;
export const SCREEN_WIDTH_MOBILE_SM = 319;

export const PAF_UNDERPERFORMANCE_CUTOFF = 0.85;
export const PAF_OVERPERFORMANCE_CUTOFF = 1.15;

export const LOGIN_RETURN_PATH_KEY = 'loginReturnPath';
export const WIZARD_INIT_ASSIGNMENT_KEY = 'wizardCoreSettings';
export const NUM_NOTIFICATIONS_KEY = 'numNotifications';

export const KEY_TABLE_PAGE_SIZE = 'peerceptiv-table-page-size';
export const KEY_TUTORIAL_SEEN = 'peerceptiv-tutorial-seen';
export const KEY_SELECTED_USER = 'peerceptiv-selected-user';
export const KEY_SELECTED_COMMENT = 'peerceptiv-selected-comment';
export const KEY_SELECTED_RATING = 'peerceptiv-selected-rating';
export const KEY_SELECTED_TAG = 'peerceptiv-selected-tag';
export const KEY_ACCESSIBILITY_ANIM_OFF = 'peerceptiv-accesibility-animations-off';

export const UUID_REGEX = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
export const LOGIN_REDIRECT_EXCEPTION_REGEXES = [
  new RegExp(`\/reset_password\/${UUID_REGEX}`, 'g'),
  new RegExp(`\/user\/${UUID_REGEX}\/verify\/${UUID_REGEX}`, 'g'),
  new RegExp(`\/user\/${UUID_REGEX}\/activate\/${UUID_REGEX}`, 'g'),
];

export const ROUTES = [
  { path: '/course/:courseId/assignment/:assignmentId/results' },
  { path: '/course/:courseId/assignment/:assignmentId/dashboard' },
  { path: '/course/:courseId/assignment/:assignmentId/rubric' },
  { path: '/course/:courseId/assignment/:assignmentId/submission/:submissionId?' },
  { path: '/course/:courseId/assignment/:assignmentId/instructor-submission' },
  { path: '/course/:courseId/assignment/:assignmentId/review/:reviewId/feedback' },
  { path: '/course/:courseId/assignment/:assignmentId/review/:reviewId' },
  { path: '/course/:courseId/assignment/:assignmentId/progress' },
  { path: '/course/:courseId/assignment/:assignmentId/copy' },
  { path: '/course/:courseId/assignment/:assignmentId/edit' },
  { path: '/course/:courseId/assignment/:assignmentId/groups/:groupId/add' },
  { path: '/course/:courseId/assignment/:assignmentId/groups' },
  { path: '/course/:courseId/assignment/menu' },
  { path: '/course/:courseId/assignment/workflows' },
  { path: '/course/:courseId/assignment/type' },
  { path: '/course/:courseId/assignment/setup' },
  { path: '/course/:courseId/assignment/new' },
  { path: '/course/:courseId/assignment/copy-list' },
  { path: '/course/:courseId/assignments' },
  { path: '/course/:courseId/roster' },
  { path: '/course/dashboard/new' },
  { path: '/course/dashboard/edit' },
  { path: '/course/dashboard/copy' },
  { path: '/course/dashboard' },
  { path: '/profile/change_password' },
  { path: '/profile' },
  { path: '/login' },
  { path: '/logout' },
  { path: '/register' },
  { path: '/forgot_password' },
  { path: '/reset_password/:resetPasswordCode' },
  { path: '/user/:userId/verify/:verificationCode' },
  { path: '/error-log' },
  { path: '/' },
];

/**
 * Default Assignment used for Assignment Wizard
 */
export const DEFAULT_ASSIGNMENT: Assignment = {
  // CoreSettings
  courseId: '',
  peerEvaluationOnly: false,
  groupFormationOnly: false,
  groupsEnabled: false,
  instructorUpload: false,
  peerEvaluationEnabled: false,
  instructorGradedOnly: false,
  feedbackEnabled: true,
  studentSelectedEvaluations: false,
  liveMode: false,
  liveModeReviewAll: false,
  reflectionEnabled: false,

  // DetailsProps
  assignmentName: '',
  assignmentDescription: '',

  // GroupFormationProps
  groupFormationGroupNum: 4,

  // SubmissionProps
  allowFileSubmissions: true,
  allowUrlSubmissions: true,
  allowTextSubmissions: true,

  // Review1Props & Review2Props = {
  reviewingCirclesEnabled: false,
  reviewsManuallyAssigned: false,
  numberOfReviewers: 3,
  allowBonusReviews: false,
  reviewingWithoutSubmission: false,
  selfReviewEnabled: false,

  // FeedbackProps = {
  feedbackPrompt:
    'If you found the review helpful, what made it useful? If possible, suggest a way to be more helpful next time.',
  feedbackRatings: [
    { score: 1, levelDescription: '1 - Less helpful than average or unhelpful' },
    { score: 2, levelDescription: '2 - Average helpfulness' },
    { score: 3, levelDescription: '3 - Slightly more helpful feedback than average' },
    { score: 4, levelDescription: '4 - Much more helpful feedback than average' },
    { score: 5, levelDescription: '5 - Outstanding helpfulness; goes above and beyond' },
  ],

  // EvaluationProps
  memberEvalEnabled: true,
  groupEvalEnabled: false,
  instructorEvalEnabled: false,
  leaderEvalEnabled: false,
  selfEvalEnabled: false,
  usePeerEvalRatingsForGrades: false,
  showEvaluatorIdentities: false,
  pointsAllocationEvalEnabled: false,

  // Grading1Props & Grading2Props & Grading3Props
  benchmarkGradingEnabled: false,
  teacherReviewOverridesStudents: false,
  manualGradeRelease: false,
  hideRatingResults: false,
  hideCommentResults: false,
  hideGradeResults: false,
  meanGrade: 85,
  standardDeviation: 10,
  taskGradeWeight: 20,
  reviewingGradeWeight: 40,
  writingGradeWeight: 40,
  peerEvaluationGradeWeight: 0,
  evalRatingGradeWeight: 0,
  preventLateSubmissions: false,
  lateSubmissionPenaltiesEnabled: true,
  dailyLatePenalty: 10,
  zeroGradeIfEvalsIncomplete: false,

  // DeadlinesProps = {
  asyncEndDeadline: null,
  publicationTime: '',
  deadlineTime: '23:59',
  submissionDeadline: '',
  reviewDeadline: '',
  feedbackDeadline: '',
  reflectionDeadline: '',
  peerEvaluationDeadline: null,
  groupFormationDeadline: null,

  // AdvancedProps
  convertSubmissionsToPdf: true,
  emailNotificationsEnabled: true,

  //Lti
  ltiResourceLinkId: '',

  //LTI Connection
  connectedToLineItem: false,
  lineItemId: '',
};
