import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Comment, Rating, Rubric, RubricTarget, RubricTemplate } from '../../types/types';
import { getRubricTemplate, importRubricTemplateToAssignmentRubric, updateRubricTemplate } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import RubricEditorPage from './RubricEditorPage';

function RubricTemplatePage(): JSX.Element {
  const { rubricTemplateId, courseId, assignmentId, target } = useParams() as {
    rubricTemplateId: string;
    courseId?: string;
    assignmentId?: string;
    target?: RubricTarget;
  };

  const navigate = useNavigate();

  const [rubric, setRubric] = useState<RubricTemplate | null>(null);

  useEffect(() => getRubricTemplate(rubricTemplateId, setRubric), [rubricTemplateId]);

  const onFavoriteToggle = useCallback(
    () => updateRubricTemplate(rubricTemplateId, { ...rubric, favorite: !rubric?.favorite }, setRubric),
    [rubricTemplateId, rubric],
  );

  const onReorder = useCallback(
    (prompts: Rubric) => {
      if (rubric) {
        const newRubric: RubricTemplate = {
          ...rubric,
          items: prompts,
          commentsSet: prompts.filter((prompt) => prompt.hasOwnProperty('commentId')) as Comment[],
          ratingsSet: prompts.filter((prompt) => prompt.hasOwnProperty('ratingId')) as Rating[],
        };
        updateRubricTemplate(rubric.rubricTemplateId, newRubric, setRubric);
      }
    },
    [rubric],
  );

  const onRename = useCallback(
    (name: string) => {
      if (rubric) updateRubricTemplate(rubric.rubricTemplateId, { ...rubric, name }, setRubric);
    },
    [rubric],
  );

  const onImport = useCallback(
    (rubricTemplate: RubricTemplate) =>
      assignmentId && target
        ? importRubricTemplateToAssignmentRubric(assignmentId, target, rubricTemplate.rubricTemplateId, () =>
            navigate(`/course/${courseId}/assignment/${assignmentId}/rubric/${target}`),
          )
        : undefined,
    [assignmentId, courseId, navigate, target],
  );

  if (rubric) {
    return (
      <RubricEditorPage
        rubric={rubric}
        onFavoriteToggle={onFavoriteToggle}
        onReorder={onReorder}
        onRename={onRename}
        onImport={onImport}
      />
    );
  }

  return <LoadingSpinner />;
}
export default RubricTemplatePage;
