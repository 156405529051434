import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Assignment } from '../../../types/types';
import { choosePlural, conditionallyPlural } from '../../../utils/functions';
import Button from '../../core/button/Button/Button';
import AlertBar from '../../core/display/AlertBar';

type ActionData = {
  buttonAlwaysVisible?: boolean;
  complete: boolean;
  current: boolean;
  disabled?: boolean;
  href?: string;
  id: string;
  text: string;
  tooltip?: string;
};

interface Props {
  assignment: Assignment;
}

function AssignmentActionsCard({ assignment }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const rootPath = `/course/${courseId}/assignment/${assignmentId}`;

  const actions = useMemo(() => {
    const actions: ActionData[] = [];

    actions.push({
      id: 'create-rubric',
      text: 'Create Rubric',
      href: `${rootPath}/rubric`,
      complete: assignment.publishableRubric === true,
      current: false,
    });
    if (!assignment.template) {
      if (assignment.instructorUpload && assignment.progressStats)
        actions.push({
          id: 'instructor-upload',
          text: 'Submit',
          href: `${rootPath}/instructor-submission`,
          complete: assignment.progressStats.submissionCount >= assignment.numberOfReviewers,
          current: false,
          buttonAlwaysVisible: true,
        });
      actions.push({
        id: 'publish',
        text: 'Publish',
        complete: assignment.progressStats?.published === true,
        current: false,
      });
      if (assignment.reviewsManuallyAssigned && assignment.progressStats) {
        actions.push({
          id: 'assign-reviews',
          text: 'Assign Reviews',
          href: `${rootPath}/manage-reviews`,
          complete:
            assignment.progressStats.submissionCount > 0 &&
            assignment.progressStats.studentsAssignedReviews === assignment.progressStats.submissionCount,
          current: false,
          disabled: assignment.progressStats.submissionPhase,
          tooltip: assignment.progressStats.submissionPhase
            ? 'Must wait until completion of Submission Phase'
            : undefined,
        });
      }
      actions.push({
        id: 'monitor-progress',
        text: 'Monitor Progress',
        href: `${rootPath}/progress`,
        complete: assignment.status !== 'UNPUBLISHED' && assignment.status !== 'ACTIVE',
        current: false,
      });
      if (assignment.benchmarkGradingEnabled)
        actions.push({
          id: 'benchmark',
          text: 'Benchmark',
          href: `${rootPath}/results/benchmark`,
          complete: assignment.benchmarkingComplete === true,
          current: false,
        });

      if (assignment.manualGradeRelease)
        actions.push({
          id: 'release-results',
          text: 'Release Results',
          href: `${rootPath}/results`,
          complete: false,
          current: false,
        });
      else if (assignment.status === 'NEEDS_GRADING')
        actions.push({
          id: 'results-pending',
          text: 'Results Pending',
          complete: false,
          current: false,
        });
      else
        actions.push({
          id: 'results-released',
          text: 'Results Released',
          complete: assignment.status === 'COMPLETE',
          current: false,
        });
    }

    let foundCurrent = false;
    for (let i = 0; i < actions.length && !foundCurrent; i++) {
      if (actions[i].complete === false) {
        actions[i].current = true;
        foundCurrent = true;
      }
    }

    return actions;
  }, [assignment, rootPath]);

  const pendingReportsCount = assignment.progressStats?.pendingReportsCount ?? 0;
  const countStudentsWithoutGroup = assignment.progressStats?.countStudentsWithoutGroup ?? 0;

  return (
    <div id="actions-card" className="card">
      <h2>Actions</h2>
      {pendingReportsCount > 0 ? (
        <AlertBar>
          {conditionallyPlural(pendingReportsCount, 'report')} pending review.&nbsp;
          <Link to={`${rootPath}/progress#reports-table`}>See Reports</Link>
        </AlertBar>
      ) : null}
      {assignment.groupFormationEnabled &&
      countStudentsWithoutGroup > 0 &&
      (assignment.status === 'COMPLETE' || assignment.status === 'NEEDS_GRADING') ? (
        <AlertBar>
          {conditionallyPlural(countStudentsWithoutGroup, 'student')} without group.&nbsp;
          <Link to={`${rootPath}/groups/student-list?withoutGroup=true`}>
            Assign to group{choosePlural(countStudentsWithoutGroup)}
          </Link>
        </AlertBar>
      ) : null}
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${24 * actions.length + 64 * (actions.length - 1)} 24`}
        preserveAspectRatio="none"
        aria-hidden
      >
        {actions.map((action, i) => {
          if (action.complete) {
            return (
              <g key={action.id}>
                <circle id={action.id} cx={88 * i + 12} cy="12" r="12" fill="#38B934" />
                <text className="material-icons" x={88 * i} y="24" fill="white">
                  done
                </text>
              </g>
            );
          } else if (action.current) {
            return (
              <circle
                id={action.id}
                key={action.id}
                cx={88 * i + 12}
                cy="12"
                r="8"
                fill="none"
                stroke="#2BD8F3"
                strokeWidth="8px"
              />
            );
          } else {
            return (
              <circle
                id={action.id}
                key={action.id}
                cx={88 * i + 12}
                cy="12"
                r="8"
                fill="none"
                stroke="#D6D6D6"
                strokeWidth="8px"
              />
            );
          }
        })}
        {actions.slice(1).map((action, i) => {
          let stroke = '';
          if (action.complete) {
            stroke = '#38B934';
          } else if (action.current) {
            stroke = '#2BD8F3';
          } else {
            stroke = '#D6D6D6';
          }

          return <path key={`${action.id} line`} stroke={stroke} strokeWidth="4" d={`M ${88 * i + 24},12 h 64 Z`} />;
        })}
      </svg>
      <div id="action-ctrls-wrapper">
        {actions.map((action) => (
          <ActionControl key={`${action.id} ctrl`} action={action} />
        ))}
      </div>
    </div>
  );
}

function ActionControl({ action }: { action: ActionData }): JSX.Element {
  const actionEl = useRef<HTMLDivElement>(null);

  const positionActionCtrls = useCallback(() => {
    if (actionEl.current) {
      const iconEl = document.getElementById(action.id);
      const parentEl = document.getElementById('action-ctrls-wrapper');
      if (iconEl && parentEl) {
        const xPlacement = iconEl.getBoundingClientRect().left - parentEl.getBoundingClientRect().left;
        const offset = !action.complete ? 28 : 24;
        actionEl.current.setAttribute('style', `left: ${xPlacement - offset}px;`);
      }
    }
  }, [action, actionEl]);

  useEffect(() => {
    positionActionCtrls();
    window.addEventListener('resize', positionActionCtrls);

    return () => window.removeEventListener('resize', positionActionCtrls);
  }, [positionActionCtrls]);

  return (
    <div className="action-wrapper" ref={actionEl}>
      {(action.href && !action.complete && action.current) || action.buttonAlwaysVisible ? (
        <Button
          variant="low xs"
          href={!action.disabled ? action.href : undefined}
          route
          ariaCurrent={action.current}
          disabled={action.disabled}
          tooltip={action.tooltip}
        >
          {action.text}
          {action.complete ? <span className="sr-only"> (Complete)</span> : null}
        </Button>
      ) : (
        <span>
          {action.text}
          {action.complete ? <span className="sr-only"> (Complete)</span> : null}
          {action.current ? <span className="sr-only"> (Current)</span> : null}
          {!(action.complete || action.current) ? <span className="sr-only"> (Unavailable)</span> : null}
        </span>
      )}
    </div>
  );
}

export default AssignmentActionsCard;
