import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import { RootState } from '../../store';
import { selectAssignment } from '../../store/selectors';
import { AssignmentProgress, EvaluationScoreItemCatalog } from '../../types/types';
import { getEvalScoreItemCatalog, getStudentAssignmentProgress } from '../../utils/requests';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import EvalEditor from './EvalEditor';
import EvalSelectScreen from './EvalSelectScreen';
import EvalSelfSelect from './EvalSelfSelect';
import PointsAllocation from './PointsAllocation';

function EvaluationPage(): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const dispatch = useDispatch();

  const [userProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const [catalog, setCatalog] = useState<EvaluationScoreItemCatalog | null>(null);
  const [updateKey, setUpdateKey] = useState(0);

  const assignment = useSelector(selectAssignment);
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    getStudentAssignmentProgress(assignmentId, setAssignmentProgress);
    getEvalScoreItemCatalog(assignmentId, user.userId, setCatalog);
  }, [updateKey, dispatch, assignmentId, user.userId]);

  const updateCb = useCallback(() => setUpdateKey((prevKey) => prevKey + 1), []);

  if (assignment && userProgress && catalog)
    return (
      <div className="page" id="evaluation-page">
        <Routes>
          <Route index element={<EvalSelectScreen catalog={catalog} userProgress={userProgress} />} />
          <Route path={`/select_peers`} element={<EvalSelfSelect catalog={catalog} updateCb={updateCb} />} />
          <Route
            path="/:peerEvalId"
            element={<EvalEditor updateCb={updateCb} canEvaluate={userProgress.status.canEvaluate} />}
          />
          <Route path="/points" element={<PointsAllocation userProgress={userProgress} updateCb={updateCb} />} />
        </Routes>
      </div>
    );
  return <LoadingSpinner />;
}

export default EvaluationPage;
