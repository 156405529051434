import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AssignmentProgress,
  EvaluationTarget,
  Rating,
  Result,
  ReviewCommentWithResultData,
  UserRatingScore,
} from '../../types/types';
import { getEvalCommentsWithResultsData, getMyGradeResults, getStudentEvalRatingScores } from '../../utils/requests';
import StudentResultsTutorial from '../tutorial/StudentResultsTutorial';
import { useSelector } from 'react-redux';
import { selectAssignment, selectUser } from '../../store/selectors';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import {
  getBackgroundColorFromScore,
  getDefaultEvalTargetForAssignment,
  getEvalTargetFormattedFull,
} from '../../utils/functions';
import Icon from '../core/display/Icon';
import Button from '../core/button/Button/Button';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import RichReader from '../core/display/RichReader';
import RadarChart, { RadarDataSeries } from '../core/display/Graph/RadarChart';
import { PAF_OVERPERFORMANCE_CUTOFF, PAF_UNDERPERFORMANCE_CUTOFF } from '../../utils/constants';
import ProgressRing from '../core/display/Progress/ProgressRing';

interface Props {
  assignmentProgress: AssignmentProgress;
}

function NewStudentEvalResults({ assignmentProgress }: Props): JSX.Element {
  const { assignment } = assignmentProgress;
  const { hideRatingResults, hideCommentResults } = assignment;

  const user = useSelector(selectUser);

  const [target, setTarget] = useState<EvaluationTarget>(getDefaultEvalTargetForAssignment(assignment));

  const targetList = useMemo(() => {
    const targetList: EvaluationTarget[] = [];
    if (assignment.memberEvalEnabled) targetList.push('MEMBER');
    if (
      assignment.leaderEvalEnabled &&
      assignmentProgress.group?.groupMembers.some(
        (groupMember) => groupMember.user.userId === user.userId && groupMember.groupLeader,
      )
    )
      targetList.push('GROUP_LEADER');
    if (assignment.groupEvalEnabled) targetList.push('GROUP');
    return targetList;
  }, [assignment, assignmentProgress, user]);

  if (assignmentProgress.status.hasResult) {
    const sharedProps = { assignmentProgress, target, targetList };
    return (
      <>
        <GradesSection {...sharedProps} />
        {targetList.length > 1 ? (
          <div className="target-choice-wrapper">
            <label htmlFor="target-choice">Select Evaluation Type:</label>
            <select id="target-choice" value={target} onChange={(e) => setTarget(e.target.value as EvaluationTarget)}>
              {targetList.map((target) => (
                <option key={target} value={target}>
                  {getEvalTargetFormattedFull(target)} Evaluation
                </option>
              ))}
            </select>
          </div>
        ) : null}
        {assignment.pointsAllocationEvalEnabled && !hideRatingResults ? (
          <PointsAllocationSection {...sharedProps} />
        ) : null}
        {!hideRatingResults ? <RatingSection {...sharedProps} /> : null}
        {!hideCommentResults ? <CommentSection {...sharedProps} /> : null}

        <StudentResultsTutorial resourcesAvailable={false} />
      </>
    );
  }
  return <></>;
}

interface SectionProps {
  assignmentProgress: AssignmentProgress;
  target: EvaluationTarget;
  targetList: EvaluationTarget[];
}

function GradesSection({}: SectionProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const assignment = useSelector(selectAssignment);

  const [result, setResult] = useState<Result | null>(null);

  useEffect(() => {
    getMyGradeResults(assignmentId, setResult);
  }, [assignmentId]);

  if (assignment && result) {
    if (assignment.hideGradeResults && !assignment.asyncEnabled) return <></>;

    const { grade, asyncResult } = result;

    const finalGrade = grade ? grade.overallGrade : asyncResult ? asyncResult.generatedGrade : 0;
    const { evalRatingGradeWeight } = assignment;
    return (
      <section className="grades">
        <div className="overall-wrapper">
          <h2>Overall Grade</h2>
          <div className="overall-grade" style={{ backgroundColor: getBackgroundColorFromScore(finalGrade) }}>
            <span>
              {Math.round(finalGrade)}
              <span className="percent">%</span>
            </span>
          </div>
        </div>
        <div className="sub-grades-wrapper">
          <h3>Sub Grades</h3>
          <div className="sub-grade">
            <ProgressRing
              size="sm"
              progress={Math.round(grade.evalCompletionGrade)}
              radius={20}
              strokeWidth={6}
              padding={0}
            />
            <span className="type-name">Evaluation Completion</span>
            <Icon
              code="info_outline"
              tooltipContent={
                <div className="explainer">
                  <p>
                    <b>Evaluation Completion</b> is a simple measure of whether you did all the required evaluations in
                    the assignment. If you completed all the evaluations, you receive 100%.
                  </p>
                  <p>({100 - evalRatingGradeWeight}% of Overall Grade)</p>
                </div>
              }
              tooltip
            />
          </div>
          {!assignment.pointsAllocationEvalEnabled ? (
            <div className="sub-grade">
              <ProgressRing
                size="sm"
                progress={Math.round(grade.evalRatingGrade)}
                radius={20}
                strokeWidth={6}
                padding={0}
              />
              <span className="type-name">Evaluation Rating</span>
              <Icon
                code="info_outline"
                tooltipContent={
                  <div className="explainer">
                    <p>
                      <b>Evaluation Rating:</b> Evaluations from your peers are used to generate this grade.
                    </p>
                    <p>({evalRatingGradeWeight}% of Overall Grade)</p>
                  </div>
                }
                tooltip
              />
            </div>
          ) : null}
        </div>
      </section>
    );
  }
  return <LoadingSpinner />;
}

function RatingSection({ assignmentProgress, target }: SectionProps): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [evalScores, setEvalScores] = useState<UserRatingScore[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);

  const getPercentOfScore = (score: number, rating: Rating) => (score * 100) / rating.ratingLevels.length;

  const labels = useMemo(() => evalScores.map((evalScore) => evalScore.rating?.name ?? ''), [evalScores]);
  const peerScores = useMemo(
    () =>
      evalScores.map((evalScore, i) => ({
        x: i,
        y: getPercentOfScore(evalScore.userScore, evalScore.rating as Rating),
      })),
    [evalScores],
  );
  const instructorScores = useMemo(
    () =>
      evalScores.map((evalScore, i) => ({
        x: i,
        y: getPercentOfScore(evalScore.instructorReviewScore, evalScore.rating as Rating),
      })),
    [evalScores],
  );
  const selfScores = useMemo(
    () =>
      evalScores.map((evalScore, i) => ({
        x: i,
        y: getPercentOfScore(evalScore.selfReviewScore, evalScore.rating as Rating),
      })),
    [evalScores],
  );
  const classAvgScores = useMemo(
    () =>
      evalScores.map((evalScore, i) => ({
        x: i,
        y: getPercentOfScore(evalScore.averageScore, evalScore.rating as Rating),
      })),
    [evalScores],
  );

  const hasSelfScores = useMemo(() => selfScores.length > 0 && selfScores[0].y > 0, [selfScores]);
  const hasInstructorScores = useMemo(
    () => instructorScores.length > 0 && instructorScores[0].y > 0,
    [instructorScores],
  );

  const dataSeries = useMemo(() => {
    const dataSeries: RadarDataSeries[] = [];
    if (filterList.includes('Class Average')) dataSeries.push({ data: classAvgScores, color: '#E96161' });
    if (hasSelfScores && filterList.includes('Self')) dataSeries.push({ data: selfScores, color: '#55C92D' });
    if (hasInstructorScores && filterList.includes('Instructor'))
      dataSeries.push({ data: instructorScores, color: '#E4C445' });
    if (filterList.includes('Peer')) dataSeries.push({ data: peerScores, color: '#7878f1' });

    return dataSeries;
  }, [classAvgScores, filterList, hasInstructorScores, hasSelfScores, instructorScores, peerScores, selfScores]);

  useEffect(() => {
    getStudentEvalRatingScores(assignmentId, target, setEvalScores);
  }, [assignmentId, target]);

  const filterTabKey = `11${hasInstructorScores ? 1 : 0}${hasSelfScores ? 1 : 0}`;

  if (evalScores.length > 0) {
    return (
      <>
        <section className="chart-wrapper">
          <RadarChart dataSeries={dataSeries} maxDomain={100} labels={labels} width={256} height={256} />
          <FilterTab key={filterTabKey} label="Show Evaluation Type:" setFilterList={setFilterList}>
            <FilterTab.Button id="btn-peer" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
              Peer
            </FilterTab.Button>
            {hasInstructorScores ? (
              <FilterTab.Button id="btn-instructor" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
                Instructor
              </FilterTab.Button>
            ) : null}
            {hasSelfScores ? (
              <FilterTab.Button id="btn-self" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
                Self
              </FilterTab.Button>
            ) : null}
            <FilterTab.Button id="btn-average" type="checkbox" name="line-filters" defaultChecked tabIndex={-1}>
              Class Average
            </FilterTab.Button>
          </FilterTab>
        </section>
        <section className="rating-scores">
          <h2>Evaluation Scores</h2>
          {evalScores.length > 0
            ? evalScores.map((ratingScore) => (
                <RatingDisplay
                  key={ratingScore.id}
                  target="MEMBER"
                  ratingScore={ratingScore}
                  assignmentProgress={assignmentProgress}
                />
              ))
            : null}
        </section>
      </>
    );
  }
  return <></>;
}

interface RatingDisplayProps extends Omit<SectionProps, 'targetList'> {
  ratingScore: UserRatingScore;
}

function RatingDisplay({ assignmentProgress, ratingScore, target }: RatingDisplayProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  const user = useSelector(selectUser);
  const assignment = useSelector(selectAssignment);

  const groupMembers = useMemo(
    () =>
      assignmentProgress.group?.groupMembers.filter(
        (groupMember) => groupMember.user.userId !== user.userId || target === 'GROUP',
      ) ?? [],
    [assignmentProgress.group?.groupMembers, target, user.userId],
  );
  const sortedLevels = useMemo(() => ratingScore.rating?.ratingLevels.sort((a, b) => b.score - a.score), [ratingScore]);

  const percentFromScore = (score: number) => (score * 100) / (ratingScore.rating?.maxScore ?? 1);

  const getScoreBar = (title: string, rawScore: number, fixed: number, classExt = '') => {
    const percentScore = percentFromScore(rawScore);
    const failure = percentScore < 60;
    const barColor = failure ? '#BB4646' : '#2E74A3';
    return (
      <div className={`bar-wrapper ${classExt} ${failure ? 'fail' : ''}`}>
        <p>{title}</p>
        <div
          className="bar"
          style={{
            backgroundImage: `linear-gradient(to right, ${barColor} ${percentScore}%, rgba(255, 255, 255, 0) ${percentScore}%)`,
          }}
        >
          <span>{rawScore.toFixed(fixed)}</span>
        </div>
      </div>
    );
  };

  const { userScore, instructorReviewScore, selfReviewScore, identifierMap, averageScore } = ratingScore;
  const roundedScore = Math.round(userScore);

  return (
    <div className="rating-display">
      <h3>{ratingScore.rating?.name}</h3>
      <Button
        className="button-mini"
        classOverride
        onClick={() => setExpanded((prevState) => !prevState)}
        ariaLabel={`${expanded ? 'Close' : 'Expand'} ${ratingScore.rating?.name}`}
      >
        <Icon code={expanded ? 'arrow_drop_down' : 'arrow_right'} ariaHidden />
      </Button>
      <div className="score-col column">
        {expanded ? (
          <>
            {getScoreBar('Peer Evaluation Average', userScore, 1)}
            {assignment?.showEvaluatorIdentities ? (
              <>
                <div className="arrows" />
                {groupMembers.map((groupMember) =>
                  getScoreBar(`by ${groupMember.user.name}`, identifierMap[groupMember.user.userId], 0, 'mini'),
                )}
              </>
            ) : null}
            <hr />
            {instructorReviewScore >= 0 ? (
              <>
                {getScoreBar('Instructor Evaluation', instructorReviewScore, 1)}
                <hr />
              </>
            ) : null}
            {selfReviewScore >= 0 ? (
              <>
                {getScoreBar('Self Evaluation', selfReviewScore, 0)}
                <hr />
              </>
            ) : null}
            {getScoreBar('Class Average', averageScore, 1)}
          </>
        ) : (
          <>
            {getScoreBar('Peer Evaluation', userScore, 1)}
            {instructorReviewScore >= 0 ? getScoreBar('Instructor Evaluation', instructorReviewScore, 1) : null}
            {selfReviewScore >= 0 ? getScoreBar('Self Evaluation', selfReviewScore, 0) : null}
          </>
        )}
      </div>
      <div className="prompt-col column">
        <div className="prompt-description">
          <p className="heading">
            <b>Prompt Description:</b>
          </p>
          <RichReader content={ratingScore.rating?.prompt ?? ''} />
        </div>
        {expanded
          ? sortedLevels?.map((ratingLevel) => (
              <div
                key={ratingLevel.ratingLevelId}
                className={`rating-level ${ratingLevel.score === roundedScore ? 'highlight' : ''}`}
              >
                <div className="level-score">{ratingLevel.score}</div>
                {ratingLevel.levelDescription}
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

function CommentSection({ assignmentProgress, target }: SectionProps): JSX.Element {
  const { assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [commentResultData, setCommentResultData] = useState<ReviewCommentWithResultData[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);
  const [showFilterBar, setShowFilterBar] = useState(false);

  const sortedCommentResultData = useMemo(() => {
    return commentResultData
      .filter((commentResultDatum) =>
        filterList.length > 0 ? filterList.includes(commentResultDatum.comment.commentName) : true,
      )
      .sort((a, b) => a.comment.commentName.localeCompare(b.comment.commentName));
  }, [commentResultData, filterList]);

  const commentNames = useMemo(
    () => [...new Set(commentResultData.map((commentResultDatum) => commentResultDatum.comment.commentName))].sort(),
    [commentResultData],
  );

  useEffect(() => {
    if (
      assignmentProgress &&
      assignmentProgress.status.hasResult &&
      assignmentProgress.assignment?.peerEvaluationEnabled
    ) {
      getEvalCommentsWithResultsData(assignmentId, target, setCommentResultData);
    }
  }, [assignmentId, assignmentProgress, target]);

  if (sortedCommentResultData.length > 0)
    return (
      <section className="reviewer-comments">
        <div className="title-row">
          <h2>Reviewer Comments</h2>
          <div className="ctrls">
            {commentNames.length > 1 ? (
              <Button
                ariaLabel={`${showFilterBar ? 'Unfilter' : 'Filter'} Comments`}
                tooltip={`${showFilterBar ? 'Unfilter' : 'Filter'} Comments`}
                className="button-mini"
                classOverride
                onClick={() =>
                  setShowFilterBar((prevState) => {
                    if (!prevState === false) setFilterList([]);
                    return !prevState;
                  })
                }
              >
                <Icon code={`filter_alt${showFilterBar ? '_off' : ''}`} />
              </Button>
            ) : null}
          </div>
        </div>
        {showFilterBar ? (
          <div className="filter-bar">
            <FilterTab label="Filter by:" setFilterList={setFilterList}>
              {commentNames.map((commentName) => (
                <FilterTab.Button key={commentName} type="radio" name="comment-filters">
                  {commentName}
                </FilterTab.Button>
              ))}
            </FilterTab>
          </div>
        ) : null}

        {sortedCommentResultData.map((commentResultDatum, i) => (
          <blockquote
            key={`${commentResultDatum.reviewId};${commentResultDatum.comment.commentId};${i}`}
            className={commentResultDatum.instructorReview ? 'instructor-comment' : undefined}
          >
            <div className="top-row">
              <span className="comment-name">
                {commentResultDatum.userName ? `${commentResultDatum.userName} ` : ''}on{' '}
                <b>{commentResultDatum.comment.commentName}</b>
              </span>
            </div>
            {commentResultDatum.reviewComments.map((reviewComment) => (
              <p key={reviewComment.commentId}>{reviewComment.comment}</p>
            ))}
          </blockquote>
        ))}
      </section>
    );
  return <></>;
}

function PointsAllocationSection({ assignmentProgress }: SectionProps): JSX.Element {
  const { result, group, assignment } = assignmentProgress;
  if (result && group) {
    const { peerAssessmentFactor } = result;
    const percentFromFactor = Math.round(peerAssessmentFactor * 100);
    const equalPointsValue = 100 / (group.groupMembers.length + (assignment.selfEvalEnabled ? 0 : -1));
    const avgPointsReceived = equalPointsValue * peerAssessmentFactor;
    const backgroundStyle: React.CSSProperties = {
      backgroundColor: performanceTextFromPAF(peerAssessmentFactor, '#eb7373', '#83d3eb', '#58cf7c'),
    };
    return (
      <section className="rating-scores">
        <h2>Your Allocated Points</h2>
        <div className="rating-display">
          <h3>Points Allocation</h3>
          <div className="score-col column">
            <div className="score" style={backgroundStyle}>
              <span className="lg">{percentFromFactor}</span>
              <span className="sm">%</span>
            </div>
          </div>
          <div className="prompt-col column">
            <p>
              You received <b style={backgroundStyle}>{percentFromFactor}%</b> of your share of the points.
            </p>
            <p>
              That&apos;s{' '}
              <b style={backgroundStyle}>
                {Number(avgPointsReceived.toFixed(1))} points out of {Number(equalPointsValue.toFixed(1))}
              </b>{' '}
              on average (where each member gets {Number(equalPointsValue.toFixed(1))} points if distributed equally).
            </p>
            <p>
              This means you{' '}
              <b style={backgroundStyle}>
                {performanceTextFromPAF(
                  peerAssessmentFactor,
                  'underperformed',
                  'performed satisfactorily',
                  'overperformed',
                )}
              </b>{' '}
              relative to your peers.
            </p>
          </div>
        </div>
      </section>
    );
  }
  return <></>;
}

export const performanceTextFromPAF = (
  paf: number,
  underText: string,
  defaultText: string,
  overText: string,
): string => {
  if (paf < PAF_UNDERPERFORMANCE_CUTOFF) return underText;
  if (paf > PAF_OVERPERFORMANCE_CUTOFF) return overText;
  return defaultText;
};

export default NewStudentEvalResults;
