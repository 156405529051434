import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Form from '../core/input/Form/Form';
import { AssignmentProgress, EvaluationScoreItemCatalog, User } from '../../types/types';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/selectors';
import _ from 'lodash';
import LiveEvalScoreboard from './LiveEvalScoreboard';
import { getEvalScoreItemCatalog, savePointAttributions } from '../../utils/requests';
import { useNavigate, useParams } from 'react-router-dom';
import AlertBar from '../core/display/AlertBar';
import { PAF_OVERPERFORMANCE_CUTOFF, PAF_UNDERPERFORMANCE_CUTOFF } from '../../utils/constants';
import Icon from '../core/display/Icon';

type PointAttribution = {
  peer: User;
  points: number;
  paf: number;
  comment?: string;
};

interface Props {
  updateCb: () => void;
  userProgress: AssignmentProgress;
}

function PointsAllocation({ updateCb, userProgress }: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };

  const [catalog, setCatalog] = useState<EvaluationScoreItemCatalog | null>(null);
  const [pointAttribution, setPointAttribution] = useState<PointAttribution[] | null>(null);

  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const sum = useMemo(
    () =>
      pointAttribution ? pointAttribution.map((attr) => attr.points).reduce((partialSum, a) => partialSum + a, 0) : 0,
    [pointAttribution],
  );

  useEffect(() => getEvalScoreItemCatalog(assignmentId, user.userId, setCatalog), [assignmentId, user]);

  useEffect(() => {
    if (catalog && catalog.groupMemberEvalItems.length > 0) {
      const items = catalog.groupMemberEvalItems.sort((a, b) =>
        (a.targetUser?.name ?? '').localeCompare(b.targetUser?.name ?? ''),
      );
      if (items) console.log(items);
      setPointAttribution(
        items.map((item) => ({
          peer: item.targetUser as User,
          points: item.score >= 0 ? item.score : 0,
          paf: (item.score * items.length) / 100,
          comment:
            item.peerEvaluation.reviewComments.length > 0 ? item.peerEvaluation.reviewComments[0].comment : undefined,
        })),
      );
    }
  }, [userProgress, user, catalog]);

  const formPages = useMemo(() => {
    if (pointAttribution) {
      const sharedProps = {
        pointAttribution,
        setPointAttribution,
      };
      return [
        <EditorFormPage key="page-1" {...sharedProps} />,
        ...(sum === 100
          ? pointAttribution
              .filter((attr) => isPafOutlier(attr.paf))
              .map((attr) => <CommentFormPage key={attr.peer.userId} attr={attr} {...sharedProps} />)
          : []),
        <ConfirmFormPage key="page-2" {...sharedProps} />,
      ];
    }
    return [];
  }, [pointAttribution, sum]);

  const handleSubmit = useCallback(
    () =>
      savePointAttributions(
        assignmentId,
        pointAttribution?.map((attr) => ({
          peerUserId: attr.peer.userId,
          points: attr.points,
          comment: isPafOutlier(attr.paf) ? attr.comment : undefined,
        })) ?? [],
        () => {
          updateCb();
          navigate(`/course/${courseId}/assignment/${assignmentId}/evaluate/`);
        },
      ),
    [assignmentId, courseId, navigate, pointAttribution, updateCb],
  );

  return <Form id="eval-points-allocation-form" pages={formPages} onSubmit={handleSubmit} />;
}

interface FormPageProps {
  pointAttribution: PointAttribution[];
  setPointAttribution: React.Dispatch<React.SetStateAction<PointAttribution[] | null>>;
}

function EditorFormPage({ pointAttribution, setPointAttribution }: FormPageProps): JSX.Element {
  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const sum = useMemo(
    () => pointAttribution.map((attr) => attr.points).reduce((partialSum, a) => partialSum + a, 0),
    [pointAttribution],
  );

  useEffect(() => {
    const inputEls = inputWrapperRef.current?.querySelectorAll('input');
    inputEls?.forEach((inputEl) => {
      if (sum !== 100) inputEl.setCustomValidity(`Distributed points must add up to 100. (Current total: ${sum})`);
      else inputEl.setCustomValidity('');
    });
  }, [sum]);

  return (
    <>
      <Form.Header>
        <Form.Title>Team Member Performance</Form.Title>
        <Form.Description size="sm">
          Distribute points among your team members according to the amount of work they contributed to the project. You
          have a total of 100 points to distribute. You must distribute all 100 points.
        </Form.Description>
      </Form.Header>
      <Form.Body>
        <div className="allocate-points-wrapper">
          <div className={`allocation-status ${sum === 100 ? 'done' : null}`}>
            <span>Points allocated: </span>
            <span>
              {sum}/100 {sum === 100 ? <Icon code="done" /> : null}
            </span>
          </div>
          <div ref={inputWrapperRef}>
            <table className="allocate-points-table nice-table">
              <thead className="sr-only">
                <tr>
                  <th>Group Member</th>
                  <th>Points Allocated</th>
                </tr>
              </thead>
              <tbody>
                {pointAttribution.map((attribution, i) => {
                  const { peer, points } = attribution;
                  const id = `points-${peer.userId}`;
                  return (
                    <tr key={peer.userId}>
                      <td className="name">
                        <label htmlFor={id}>{peer.name}</label>
                      </td>
                      <td className="pts">
                        <input
                          id={id}
                          type="number"
                          value={points}
                          onChange={(e) => {
                            setPointAttribution((prevAttribution) => {
                              const newAttribution = _.cloneDeep(prevAttribution);
                              if (newAttribution) {
                                const newPoints = Math.min(Math.max(parseInt(e.target.value), 0), 100);
                                const sum = newAttribution
                                  .map((attr, j) => (i === j ? newPoints : attr.points))
                                  .reduce((partialSum, a) => partialSum + a, 0);
                                if (sum <= 100) {
                                  newAttribution[i].points = newPoints;
                                  newAttribution[i].paf = (newPoints * newAttribution.length) / 100;
                                }
                              }
                              return newAttribution;
                            });
                          }}
                        />
                        pts
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Form.Body>
    </>
  );
}

interface CommentFormPageProps extends FormPageProps {
  attr: PointAttribution;
}

function CommentFormPage({ attr, setPointAttribution }: CommentFormPageProps): JSX.Element {
  const isGood = attr.paf - 1 > 0;
  return (
    <>
      <Form.Header>
        <Form.Title>Provide Comment</Form.Title>
        <Form.Description size="sm">
          <AlertBar backgroundColor={isGood ? '#c1daf7' : undefined}>
            {attr.peer.name} seems to have {isGood ? 'performed well' : 'underperformed'}
          </AlertBar>
          <p>
            Please explain your {isGood ? 'high' : 'low'} point allocations for <b>{attr.peer.name}</b>, providing
            concrete examples to illustrate your reasoning. *Only the instructional team will be able to view your
            response to this question.
          </p>
        </Form.Description>
      </Form.Header>
      <Form.Body>
        <label htmlFor={`comment-${attr.peer.userId}`}>
          Evaluation of <b>{attr.peer.name}</b>
        </label>
        <textarea
          id={`comment-${attr.peer.userId}`}
          required
          value={attr.comment}
          onChange={(e) => {
            setPointAttribution((prevAttribution) => {
              const newAttribution = _.cloneDeep(prevAttribution);
              if (newAttribution) {
                const i = newAttribution.findIndex((currAttr) => currAttr.peer.userId === attr.peer.userId);
                if (i > -1) {
                  newAttribution[i].comment = e.target.value;
                }
                return newAttribution;
              }
              return prevAttribution;
            });
          }}
        />
      </Form.Body>
    </>
  );
}

function ConfirmFormPage({ pointAttribution }: FormPageProps): JSX.Element {
  return (
    <>
      <Form.Header>
        <Form.Title>Review & Submit</Form.Title>
        <Form.Description size="sm">
          Please review the team member rankings below. These rankings were determined by your point distribution. Once
          you feel your evaluation is accurate, click &quot;Submit&quot; to finish.
        </Form.Description>
      </Form.Header>
      <Form.Body>
        {pointAttribution
          .sort((a, b) => {
            if (a.points < b.points) return 1;
            else if (a.points > b.points) return -1;
            return 0;
          })
          .map((attr, i) => {
            const { peer, points } = attr;
            return (
              <LiveEvalScoreboard.LiveScoreboardEntry
                key={peer.userId}
                rank={i + 1}
                score={points}
                user={peer}
                current={false}
                end
                title="MEMBER"
              />
            );
          })}
      </Form.Body>
    </>
  );
}

const isPafOutlier = (paf: number) => paf < PAF_UNDERPERFORMANCE_CUTOFF || paf > PAF_OVERPERFORMANCE_CUTOFF;

export default PointsAllocation;
