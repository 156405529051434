import React from 'react';
import { RatingScore, ReviewableByItem } from '../../../types/types';

interface Props {
  heading: string;
  memberRatingScores?: RatingScore[];
  reviewableByItem: ReviewableByItem;
}

function RatingDetailsTable({ heading, memberRatingScores, reviewableByItem }: Props): JSX.Element {
  return (
    <>
      <h3>{heading}</h3>
      <section className="rating-table">
        <table className="nice-table">
          <thead>
            <tr>
              <th>Rating</th>
              <th>Score</th>
              <th>Description</th>
              {memberRatingScores ? (
                <>
                  <th>Average Score</th>
                  <th>Difference</th>
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {reviewableByItem.ratings.map((ratingItem) => {
              const { rating, reviewRating } = ratingItem;
              const avgScore =
                memberRatingScores?.find((score) => score.id === ratingItem.reviewRating?.ratingId)?.averageScore ?? 0;
              return (
                <tr key={`rating-${rating.ratingId}`}>
                  <td>{rating.name}</td>
                  {reviewRating !== null ? (
                    <>
                      <td className="score">
                        <span>
                          {reviewRating.score}
                          {rating.ratingLevels.length > 1
                            ? `/${rating.ratingLevels[rating.ratingLevels.length - 1].score}`
                            : null}
                        </span>
                      </td>
                      <td>
                        {reviewRating.score < rating.ratingLevels.length
                          ? rating.ratingLevels[reviewRating.score - 1].levelDescription
                          : null}
                      </td>
                    </>
                  ) : (
                    <>
                      <td>N/A</td>
                      <td className="incomplete-action-txt">
                        {reviewableByItem.user.name} has not yet provided a rating
                      </td>
                    </>
                  )}
                  {memberRatingScores && reviewRating ? (
                    <>
                      <td className="score">
                        <span>{avgScore}</span>
                      </td>
                      <td>
                        {reviewRating.score > avgScore ? '+' : null}
                        {(reviewRating.score - avgScore).toFixed(2)}
                      </td>
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    </>
  );
}

export default RatingDetailsTable;
