import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setReduxAssignment } from '../../actions';
import { selectAssignment, selectCourse } from '../../store/selectors';
import { AssignmentRubric, Rubric } from '../../types/types';
import { editAssignment, updateAssignmentRubric } from '../../utils/requests';
import RubricEditorPage from './RubricEditorPage';

interface Props {
  rubric: AssignmentRubric | undefined | null;
  setRubric: React.Dispatch<React.SetStateAction<AssignmentRubric | undefined | null>>;
}

function AssignmentSubmissionRubricPage({ rubric, setRubric }: Props): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };
  const dispatch = useDispatch();
  const course = useSelector(selectCourse);
  const assignment = useSelector(selectAssignment);

  const canToggleVisibility = useMemo(
    () =>
      course &&
      !course.asyncEnabled &&
      assignment &&
      (assignment.status === 'UNPUBLISHED' || assignment.progressStats?.submissionPhase),
    [course, assignment],
  );

  const onReorder = useCallback(
    (prompts: Rubric) => {
      if (rubric) {
        const newRubric: AssignmentRubric = {
          ...rubric,
          items: prompts,
        };
        updateAssignmentRubric(assignmentId, newRubric, setRubric);
      }
    },
    [rubric, assignmentId, setRubric],
  );

  const onRename = useCallback(
    (name: string) => {
      if (rubric) updateAssignmentRubric(assignmentId, { ...rubric, name }, setRubric);
    },
    [rubric, assignmentId, setRubric],
  );

  const onVisibilityToggle = useCallback(
    (visibile: boolean) =>
      assignment
        ? editAssignment(assignmentId, { ...assignment, hiddenRubric: !visibile }, (data) =>
            dispatch(setReduxAssignment(data)),
          )
        : undefined,
    [assignment, assignmentId, dispatch],
  );

  if (rubric) {
    return (
      <RubricEditorPage
        rubric={rubric}
        onReorder={onReorder}
        onRename={onRename}
        visible={canToggleVisibility ? assignment?.hiddenRubric === false : true}
        onVisibilityToggle={canToggleVisibility ? onVisibilityToggle : undefined}
      />
    );
  }

  return <></>;
}

export default AssignmentSubmissionRubricPage;
