import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

interface Props {
  initSchedule?: BasicSchedule[];
  onChange?: (schedule: BasicSchedule[]) => void;
  readOnly?: boolean;
}

type Weekday = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

type Availability = {
  time: string;
  isAvailable: boolean;
  percentAvailable?: number;
};

export type BasicSchedule = {
  day: Weekday;
  availability: Availability[];
};

function BasicScheduler({ initSchedule, onChange = () => undefined, readOnly = false }: Props): JSX.Element {
  const tableRef = useRef<HTMLTableElement>(null);

  const [schedule, setSchedule] = useState<BasicSchedule[]>(initSchedule ?? []);

  useEffect(() => {
    if (!initSchedule || initSchedule.length === 0) {
      setSchedule(initializeSchedule());
    }
  }, [initSchedule]);

  const toggleAvailability = useCallback((colNum: number, rowNum: number) => {
    setSchedule((prevSchedule) => {
      const newSchedule = _.cloneDeep(prevSchedule);
      if (colNum < newSchedule.length && rowNum < newSchedule[colNum].availability.length)
        newSchedule[colNum].availability[rowNum].isAvailable = !newSchedule[colNum].availability[rowNum].isAvailable;
      return newSchedule;
    });
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const cell = e.target as HTMLTableCellElement;

    let shouldPreventDefault = true;

    const coords = cell.id
      .split('-')
      .slice(1)
      .map((coord) => parseInt(coord));
    switch (e.key) {
      case 'ArrowUp':
        document.getElementById(`cell-${coords[0] - 1}-${coords[1]}`)?.focus();
        break;
      case 'ArrowDown':
        document.getElementById(`cell-${coords[0] + 1}-${coords[1]}`)?.focus();
        break;
      case 'ArrowLeft':
        document.getElementById(`cell-${coords[0]}-${coords[1] - 1}`)?.focus();
        break;
      case 'ArrowRight':
        document.getElementById(`cell-${coords[0]}-${coords[1] + 1}`)?.focus();
        break;
      case 'Home':
      case 'PageUp':
        document.getElementById(`cell-0-0`)?.focus();
        break;
      case 'End':
      case 'PageDown':
        const boxes = tableRef.current?.querySelectorAll<HTMLInputElement>(`input[type='checkbox']`);
        if (boxes && boxes.length > 0) boxes[boxes.length - 1]?.focus();
        break;
      case ' ':
      case 'Enter':
      case 'Tab':
        shouldPreventDefault = false;
    }

    if (shouldPreventDefault) e.preventDefault();
  };

  useEffect(() => onChange(schedule), [schedule, onChange]);

  const getRows = () => {
    const rows: JSX.Element[] = [];
    if (schedule.length > 0) {
      for (let rowNum = 0; rowNum < schedule[0].availability.length; rowNum++) {
        const cells: JSX.Element[] = [];
        const { time } = schedule[rowNum].availability[rowNum];
        for (let colNum = 0; colNum < schedule.length; colNum++) {
          const { day, availability } = schedule[colNum];
          const { isAvailable, percentAvailable } = availability[rowNum];
          cells.push(
            <td key={`cell-${rowNum}-${colNum}`}>
              <label htmlFor={`cell-${rowNum}-${colNum}`} className="sr-only">
                {day} {time} Availability
              </label>
              <input
                id={`cell-${rowNum}-${colNum}`}
                name="schedule"
                type="checkbox"
                checked={isAvailable}
                onChange={() => toggleAvailability(colNum, rowNum)}
                onKeyDown={handleKeyDown}
                disabled={readOnly}
              />

              <div className="visual-check-state" style={{ opacity: percentAvailable ? percentAvailable / 100 : 1 }} />
              {percentAvailable !== undefined ? (
                <span className={`percent-available ${percentAvailable === 100 ? 'full' : ''}`}>
                  {Math.round(percentAvailable)}%
                </span>
              ) : null}
            </td>,
          );
        }
        rows.push(
          <tr key={`row-${rowNum}`}>
            <th>
              {time.split(' ').map((token) => {
                return (
                  <div key={token} className={!/[a-zA-Z]/g.test(token) ? 'low' : ''} style={{ textWrap: 'nowrap' }}>
                    {token}
                  </div>
                );
              })}
            </th>
            {cells}
          </tr>,
        );
      }
    }
    return rows;
  };

  return (
    <div className={`scheduler ${readOnly ? 'read-only' : ''}`} aria-readonly={readOnly}>
      {!readOnly ? (
        <p className="explainer">
          Check the boxes <span className="green">green</span> that match your available time slots:
        </p>
      ) : null}
      <table ref={tableRef}>
        <thead>
          <tr>
            <th></th>
            {schedule.map((daySchedule) => (
              <th key={daySchedule.day}>{daySchedule.day}</th>
            ))}
          </tr>
        </thead>
        <tbody>{getRows()}</tbody>
      </table>
    </div>
  );
}

export const initializeSchedule = () => {
  const days: Weekday[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const times = ['Morning (8:00-12:00)', 'Afternoon (12:00-17:00)', 'Evening (17:00-21:00)'];
  const initAvailability = times.map((time) => ({ time, isAvailable: false }));
  const schedule: BasicSchedule[] = days.map((day) => ({
    day,
    availability: _.cloneDeep(initAvailability),
  }));
  return schedule;
};

export default BasicScheduler;
