import React, { useState, useEffect } from 'react';
import { changeNumberInputWithBounds } from '../../../utils/functions';
import HelpTag from '../../core/display/HelpTag';
import PieChart from '../../core/display/PieChart/PieChart';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface Grading6Props {
  evalRatingGradeWeight: number;
  usePeerEvalRatingsForGrades: boolean;
  zeroGradeIfEvalsIncomplete: boolean;
}

function GradingPage6({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<Grading6Props>): JSX.Element {
  const [evalRatingGradeWeight, setEvalRatingGradeWeight] = useState(assignmentSettings.evalRatingGradeWeight);
  const [zeroGradeIfEvalsIncomplete, setZeroGradeIfEvalsIncomplete] = useState(
    assignmentSettings.zeroGradeIfEvalsIncomplete,
  );

  const completionGradeWeight = 100 - evalRatingGradeWeight;

  useEffect(() => {
    updateSettings({
      evalRatingGradeWeight,
      usePeerEvalRatingsForGrades: evalRatingGradeWeight === 100,
      zeroGradeIfEvalsIncomplete,
    });
  }, [updateSettings, evalRatingGradeWeight, zeroGradeIfEvalsIncomplete]);

  return (
    <>
      <h3>Evaluation Grade Weight</h3>
      <p>Choose how to weigh the evaluation grade.</p>
      <div className="grade-weight-widget-container">
        <figure>
          <PieChart radius={128} color="#efefef">
            <PieChart.Slice percent={completionGradeWeight} color="#e2d64c" />
            <PieChart.Slice percent={evalRatingGradeWeight} color="#60d4cd" />
          </PieChart>
          <figcaption>Evaluation Grade</figcaption>
        </figure>
        <table className="formatting-table">
          <tbody>
            <tr>
              <td>
                <PieChart radius={16} color="#fef6ce">
                  <PieChart.Slice percent={completionGradeWeight} color="#e2d64c" />
                </PieChart>
              </td>
              <td>
                <input
                  id="completionGradeWeight"
                  name="completionGradeWeight"
                  type="number"
                  min="0"
                  max="100"
                  step={5}
                  value={completionGradeWeight}
                  onChange={(e) => {
                    changeNumberInputWithBounds(e.target.value, 0, 100, (newCompletionValue: number) =>
                      setEvalRatingGradeWeight(100 - newCompletionValue),
                    );
                  }}
                />
              </td>
              <td>
                <label htmlFor="completionGradeWeight">Completion Grade</label>
                <HelpTag>
                  This grade indicates the percentage of all completed evaluation steps required for this assignment.
                </HelpTag>
              </td>
            </tr>
            <tr>
              <td>
                <PieChart radius={16} color="#d7fdfa">
                  <PieChart.Slice percent={evalRatingGradeWeight} color="#60d4cd" />
                </PieChart>
              </td>
              <td>
                <input
                  id="evalRatingGradeWeight"
                  name="evalRatingGradeWeight"
                  type="number"
                  min="0"
                  max="100"
                  step={5}
                  value={evalRatingGradeWeight}
                  onChange={(e) => {
                    changeNumberInputWithBounds(e.target.value, 0, 100, setEvalRatingGradeWeight);
                  }}
                />
              </td>
              <td>
                <label htmlFor="evalRatingGradeWeight">Evaluation Rating Grade</label>
                <HelpTag>
                  This grade will be the average rating received if they completed the evaluation (and a 0 if they did
                  not).
                </HelpTag>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3>Additional Settings</h3>
      <div>
        <input
          id="zeroGradeIfEvalsIncomplete"
          name="zeroGradeIfEvalsIncomplete"
          type="checkbox"
          checked={zeroGradeIfEvalsIncomplete}
          onChange={(e) => {
            setZeroGradeIfEvalsIncomplete(e.target.checked);
          }}
        />
        <label htmlFor="zeroGradeIfEvalsIncomplete">
          Automatically issue a 0% Overall Grade if evaluations are incomplete
        </label>
        <HelpTag>
          <p>
            If enabled, students who do not complete 100% of their required evaluations will automatically receive a 0%
            Overall Grade.
          </p>
          <p>This will take effect even if Evaluation Rating Grade weight is set to 100.</p>
        </HelpTag>
      </div>
    </>
  );
}

export default GradingPage6;
