import React, { useState, useEffect } from 'react';
import { changeNumberInputWithBounds } from '../../../utils/functions';
import HelpTag from '../../core/display/HelpTag';
import Icon from '../../core/display/Icon';
import PieChart from '../../core/display/PieChart/PieChart';
import { AssignmentWizardPageProps } from '../AssignmentWizard';

export interface Grading3Props {
  taskGradeWeight: number;
  reviewingGradeWeight: number;
  writingGradeWeight: number;
  peerEvaluationGradeWeight: number;
}

function GradingPage3({
  assignmentSettings,
  updateSettings,
  onInvalid = () => undefined,
  updateValidation = () => undefined,
}: AssignmentWizardPageProps<Grading3Props>): JSX.Element {
  const omitSubmissionGrade = assignmentSettings.peerEvaluationOnly || assignmentSettings.instructorUpload;
  const omitReviewGrade = assignmentSettings.peerEvaluationOnly || assignmentSettings.instructorGradedOnly;
  const omitTaskGrade = assignmentSettings.instructorGradedOnly;
  const omitEvaluationGrade = !assignmentSettings.peerEvaluationEnabled;

  const [writingGradeWeight, setWritingGradeWeight] = useState(assignmentSettings.writingGradeWeight);
  const [reviewingGradeWeight, setReviewingGradeWeight] = useState(assignmentSettings.reviewingGradeWeight);
  const [taskGradeWeight, setTaskGradeWeight] = useState(assignmentSettings.taskGradeWeight);
  const [peerEvaluationGradeWeight, setPeerEvaluationGradeWeight] = useState(
    assignmentSettings.peerEvaluationGradeWeight,
  );

  const total = writingGradeWeight + reviewingGradeWeight + taskGradeWeight + peerEvaluationGradeWeight;
  const available = 100 - total;

  useEffect(() => {
    updateValidation((e: React.FormEvent) => {
      const formData = new FormData(e.target as HTMLFormElement);
      const writingGradeWeight = parseInt((formData.get('writingGradeWeight') as string | null) || '0');
      const reviewGradeWeight = parseInt((formData.get('reviewingGradeWeight') as string | null) || '0');
      const taskGradeWeight = parseInt((formData.get('taskGradeWeight') as string | null) || '0');
      const evaluationGradeWeight = parseInt((formData.get('evaluationGradeWeight') as string | null) || '0');
      const total = writingGradeWeight + reviewGradeWeight + taskGradeWeight + evaluationGradeWeight;
      if (total === 100) return true;
      onInvalid('Invalid Grade Weight', 'All grade weights must add up to 100.');
      return false;
    });

    return () => {
      updateValidation(undefined);
    };
  }, [updateValidation, onInvalid]);

  useEffect(() => {
    updateSettings({ writingGradeWeight, reviewingGradeWeight, taskGradeWeight, peerEvaluationGradeWeight });
  }, [updateSettings, writingGradeWeight, reviewingGradeWeight, taskGradeWeight, peerEvaluationGradeWeight]);

  return (
    <>
      <h3>Grade Weight</h3>
      <p>Allocate weight to the three grade types. Must total to 100.</p>
      <div className="grade-weight-widget-container">
        <figure>
          <PieChart radius={128} color="#efefef">
            <PieChart.Slice percent={writingGradeWeight} color="#7878f1" />
            <PieChart.Slice percent={reviewingGradeWeight} color="#e676e3" />
            <PieChart.Slice percent={taskGradeWeight} color="#e4c445" />
            <PieChart.Slice percent={peerEvaluationGradeWeight} color="#55c92d" />
          </PieChart>
          <figcaption>
            Weight Allocated:{' '}
            <span className={`weight-total ${total < 100 ? 'low' : 'done'}`}>
              {total}{' '}
              {total < 100 ? <Icon label="Insufficient" code="close" /> : <Icon label="Complete" code="check" />}
            </span>
          </figcaption>
        </figure>
        <table className="formatting-table">
          <tbody>
            {omitSubmissionGrade ? null : (
              <tr>
                <td>
                  <PieChart radius={16} color="#cacaf9">
                    <PieChart.Slice percent={writingGradeWeight} color="#7878f1" />
                  </PieChart>
                </td>
                <td>
                  <input
                    id="writingGradeWeight"
                    name="writingGradeWeight"
                    type="number"
                    min="0"
                    max={writingGradeWeight + available}
                    step={5}
                    value={writingGradeWeight}
                    onChange={(e) => {
                      changeNumberInputWithBounds(
                        e.target.value,
                        0,
                        writingGradeWeight + available,
                        setWritingGradeWeight,
                      );
                    }}
                  />
                </td>
                <td>
                  <label htmlFor="writingGradeWeight">Submission Grade</label>
                  <HelpTag>
                    The submission grade assesses the quality of the submission and is based on accuracy-adjusted peer
                    scoring.
                  </HelpTag>
                </td>
              </tr>
            )}

            {omitReviewGrade ? null : (
              <tr>
                <td>
                  <PieChart radius={16} color="#eeddf9">
                    <PieChart.Slice percent={reviewingGradeWeight} color="#e676e3" />
                  </PieChart>
                </td>
                <td>
                  <input
                    id="reviewingGradeWeight"
                    name="reviewingGradeWeight"
                    type="number"
                    min="0"
                    max={reviewingGradeWeight + available}
                    step={5}
                    value={reviewingGradeWeight}
                    onChange={(e) => {
                      changeNumberInputWithBounds(
                        e.target.value,
                        0,
                        reviewingGradeWeight + available,
                        setReviewingGradeWeight,
                      );
                    }}
                  />
                </td>
                <td>
                  <label htmlFor="reviewingGradeWeight">Review Grade</label>
                  <HelpTag>The review grade assesses the student&apos;s reviewing accuracy and helpfulness.</HelpTag>
                </td>
              </tr>
            )}

            {omitTaskGrade ? null : (
              <tr>
                <td>
                  <PieChart radius={16} color="#f4e7b6">
                    <PieChart.Slice percent={taskGradeWeight} color="#e4c445" />
                  </PieChart>
                </td>
                <td>
                  <input
                    id="taskGradeWeight"
                    name="taskGradeWeight"
                    type="number"
                    min="0"
                    max={taskGradeWeight + available}
                    step={5}
                    value={taskGradeWeight}
                    onChange={(e) => {
                      changeNumberInputWithBounds(e.target.value, 0, taskGradeWeight + available, setTaskGradeWeight);
                    }}
                  />
                </td>
                <td>
                  <label htmlFor="taskGradeWeight">Task Grade</label>
                  <HelpTag>
                    The task grade indicates the percentage of all completed steps required for this assignment.
                  </HelpTag>
                </td>
              </tr>
            )}

            {omitEvaluationGrade ? null : (
              <tr>
                <td>
                  <PieChart radius={16} color="#afe59c">
                    <PieChart.Slice percent={peerEvaluationGradeWeight} color="#55c92d" />
                  </PieChart>
                </td>
                <td>
                  <input
                    id="evaluationGradeWeight"
                    name="evaluationGradeWeight"
                    type="number"
                    min="0"
                    max={peerEvaluationGradeWeight + available}
                    step={5}
                    value={peerEvaluationGradeWeight}
                    onChange={(e) => {
                      changeNumberInputWithBounds(
                        e.target.value,
                        0,
                        peerEvaluationGradeWeight + available,
                        setPeerEvaluationGradeWeight,
                      );
                    }}
                  />
                </td>
                <td>
                  <label htmlFor="evaluationGradeWeight">Evaluation Grade</label>
                  <HelpTag>
                    The evaluation grade can factor in completion of evaluation steps, and/or the average of all
                    received evaluation ratings. You may choose how to weigh these factors on the next page.
                  </HelpTag>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default GradingPage3;
